import './DoubleButtons.css';
import { useState, memo, useEffect, useRef } from 'react';

function DoubleButtons({ isActive, leftBtnText, rightBtnText, onClickLeftBtn, onClickRightBtn }) {
  const leftBtnRef = useRef(null);
  const rightBtnRef = useRef(null);
  const [btnWidth, setBtnWidth] = useState(0);
  const btnStyle = { minWidth: `${btnWidth - 1}px` };

  useEffect(() => {
    if (leftBtnText?.length && rightBtnText?.length) {
      const leftBtnWidth = leftBtnRef.current.offsetWidth;
      const rightBtnWidth = rightBtnRef.current.offsetWidth;
      const maxWidth = Math.max(leftBtnWidth, rightBtnWidth);
      setBtnWidth(maxWidth);
    }
  }, [leftBtnText, rightBtnText]);

  return (
    <div className="double-buttons">
      <button
        ref={leftBtnRef}
        style={btnStyle}
        className={`double-buttons__btn-left ${isActive ? 'double-buttons__btn--active' : ''}`}
        onClick={onClickLeftBtn}
      >
        {leftBtnText}
      </button>
      <button
        ref={rightBtnRef}
        style={btnStyle}
        className={`double-buttons__btn-right ${!isActive ? 'double-buttons__btn--active' : ''}`}
        onClick={onClickRightBtn}
      >
        {rightBtnText}
      </button>
    </div>
  );
}

export default memo(DoubleButtons);
