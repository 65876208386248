import './ResultImage.css';
import { memo } from 'react';
import eyeArrows from '../../assets/eyeArrows.svg';
import close2 from '../../assets/close2.svg';
import { useSelector } from 'react-redux';

function ResultImage({ image, handleDelete, handleOpenEdite, text = '', classEnd = '' }) {
  const language = useSelector((state) => state.userSlice.language);

  const handleDeleteImage = () => {
    handleDelete();
  };

  return (
    <div className="result-image__wrap">
      <div
        className="result-image__img-wrap"
        onClick={handleOpenEdite}
      >
        <img
          className="result-image__img-update"
          src={eyeArrows}
          alt="img"
        />
        <img
          className={`result-image__${classEnd}`}
          src={image}
          alt="img"
        />
      </div>
      <div className="result-image__name-wrap">
        <div className="result-image__name">{text}</div>
      </div>
      <img
        className="result-image__delete"
        onClick={handleDeleteImage}
        src={close2}
        alt="img"
      />
    </div>
  );
}

export default memo(ResultImage);
