import './Header.css';
import { useState, memo } from 'react';
import notifications from '../../assets/notifications.svg';
import arrowLeft from '../../assets/arrowLeft.svg';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Popover } from '@mui/material';
import { setIsUnreadMessagesUser, setUserModalNewNotifications, setUserModalSeenNotifications } from '../../store/homeSlice';
import { useDispatch } from 'react-redux';
import { translations } from '../../localization';
import ModalNotifications from '../ModalNotifications/ModalNotifications';
import { handleCheckNewMessages, handleExit, handleUpdatedNotifications } from '../../helpers/Utils';
import SelectLanguage from '../SelectLanguage/SelectLanguage';
import logoutImg from '../../assets/logoutImg.svg';

function Header() {
  const language = useSelector((state) => state.homeSlice.language);
  const userTraining = useSelector((state) => state.homeSlice.userTraining);
  const isUnreadMessagesUser = useSelector((state) => state.homeSlice.isUnreadMessagesUser);
  const userModalNewNotifications = useSelector((state) => state.homeSlice.userModalNewNotifications);
  const is_app = useSelector((state) => state.homeSlice.is_app);
  const [anchorEl, setAnchorEl] = useState(null);
  const { userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { coachingId } = useParams();
  const { coachingDayId } = useParams();
  const { coachingPlanId } = useParams();
  let token = localStorage.getItem('token-user');

  const handleSeeAllNotifications = () => {
    setAnchorEl(null);
    dispatch(setUserModalSeenNotifications([]));
    dispatch(setUserModalNewNotifications([]));
  };

  const handleCloseModalNotifications = () => {
    setAnchorEl(null);
    if (userModalNewNotifications?.length) {
      let data = {
        notifications_id: userModalNewNotifications.map((el) => el._id),
      };
      handleUpdatedNotifications(token, data, handleClearNewNotifications);
      setTimeout(() => {
        handleCheckNewMessages(token, (res) => dispatch(setIsUnreadMessagesUser(res)));
      }, 500);
    }
  };

  const handleClearNewNotifications = () => {
    dispatch(setUserModalNewNotifications([]));
    dispatch(setUserModalNewNotifications([]));
  };

  const handleBack = () => {
    if (location.pathname.split('/').includes('instractions')) {
      navigate(-1);
      return;
    }
    if (coachingPlanId?.length) {
      navigate(`/user/${userId}/${coachingId}/${coachingDayId}`);
      return;
    }
    if (coachingDayId?.length) {
      navigate(`/user/${userId}/${coachingId}`);
      return;
    }
    if (coachingId?.length) {
      navigate(`/user/${userId}`);
      return;
    }
    navigate(-1);
  };

  const handleGetTotalTime = () => {
    let res = 0;
    if (userTraining?.days?.length) {
      let resDay = userTraining.days?.find((item) => item._id === coachingDayId);
      if (resDay.exercises?.length) {
        resDay.exercises.forEach((el) => {
          res += el.time;
        });
        return res;
      }
    }
    return res;
  };

  const handleReturnTitle = () => {
    if (location.pathname.split('/').includes('instractions')) return translations['instractions'][language];
    if (location.pathname.split('/').includes('profile')) return translations['profile'][language];
    if (location.pathname.split('/').includes('pay')) return translations['pay'][language];
    if (coachingDayId?.length)
      return (
        <>
          <span>{userTraining.days?.find((item) => item._id === coachingDayId)?.name}</span>
          <span className="header__name-span">
            {handleGetTotalTime()} {translations['minutes'][language]}
          </span>
        </>
      );
    if (coachingPlanId?.length) return 'Plan';
    if (coachingId?.length)
      return (
        <>
          <span>{userTraining.name}</span>
          <span className="header__name-span">
            {translations['finish'][language]} - 5 {translations['days'][language]}
          </span>
        </>
      );
    if (userId?.length) return translations['trainings'][language];
  };

  return (
    <div className={`header-wrap ${coachingPlanId?.length && !location.pathname.split('/').includes('instractions') ? 'displayNone' : ''}`}>
      <div className="header container">
        <div className="header__name-wrap">
          <button
            className="header__form-btn-back-wrap"
            onClick={handleBack}
          >
            <img
              className="header__form-btn-back"
              src={arrowLeft}
              alt="img"
            />
          </button>
          <div className="header__name">{handleReturnTitle()}</div>
        </div>

        <div className="header__btn-wrap">
          <SelectLanguage isHeader={true} />
          {!location.pathname.split('/').includes('profile') && (
            <NavLink
              className={`header__login-btn ${location.pathname.includes('scanning') ? 'header__login-btn--scanning' : ''}`}
              to={'scanning'}
            >
              <svg
                className="header__qrcode"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g mask="url(#mask0_2519_4085)">
                  <path
                    className="header__qrcode-path"
                    d="M3 10V4C3 3.71667 3.09583 3.47917 3.2875 3.2875C3.47917 3.09583 3.71667 3 4 3H10C10.2833 3 10.5208 3.09583 10.7125 3.2875C10.9042 3.47917 11 3.71667 11 4V10C11 10.2833 10.9042 10.5208 10.7125 10.7125C10.5208 10.9042 10.2833 11 10 11H4C3.71667 11 3.47917 10.9042 3.2875 10.7125C3.09583 10.5208 3 10.2833 3 10ZM5 9H9V5H5V9ZM3 20V14C3 13.7167 3.09583 13.4792 3.2875 13.2875C3.47917 13.0958 3.71667 13 4 13H10C10.2833 13 10.5208 13.0958 10.7125 13.2875C10.9042 13.4792 11 13.7167 11 14V20C11 20.2833 10.9042 20.5208 10.7125 20.7125C10.5208 20.9042 10.2833 21 10 21H4C3.71667 21 3.47917 20.9042 3.2875 20.7125C3.09583 20.5208 3 20.2833 3 20ZM5 19H9V15H5V19ZM13 10V4C13 3.71667 13.0958 3.47917 13.2875 3.2875C13.4792 3.09583 13.7167 3 14 3H20C20.2833 3 20.5208 3.09583 20.7125 3.2875C20.9042 3.47917 21 3.71667 21 4V10C21 10.2833 20.9042 10.5208 20.7125 10.7125C20.5208 10.9042 20.2833 11 20 11H14C13.7167 11 13.4792 10.9042 13.2875 10.7125C13.0958 10.5208 13 10.2833 13 10ZM15 9H19V5H15V9ZM19 21V19H21V21H19ZM13 15V13H15V15H13ZM15 17V15H17V17H15ZM13 19V17H15V19H13ZM15 21V19H17V21H15ZM17 19V17H19V19H17ZM17 15V13H19V15H17ZM19 17V15H21V17H19Z"
                  />
                </g>
              </svg>
            </NavLink>
          )}
          <button
            aria-describedby={'simple-popover-notifications'}
            className="header__login-btn"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <img
              src={notifications}
              alt="img"
            />
            {isUnreadMessagesUser && <div className="header__notification-new"></div>}
          </button>
          <Popover
            id={'simple-popover-notifications'}
            className="header__notifications-modal--wrap"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleCloseModalNotifications}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ModalNotifications handleClose={handleSeeAllNotifications} />
          </Popover>
          {location.pathname.split('/').includes('profile') && (
            <button
              className="header__login-btn"
              onClick={() => handleExit(is_app, false)}
            >
              <img
                src={logoutImg}
                alt="img"
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(Header);
