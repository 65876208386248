import './CoachesView.css';
import coachesBg from '../../assets/coachesBg.png';
import prtSc2 from '../../assets/prtSc2.mp4';
import aboutImageOfPeopleDes from '../../assets/aboutImageOfPeopleDes.mp4';
import CommentsBloc from '../../components/CommentsBloc/CommentsBloc';
import PageMeta from '../../components/PageMeta/PageMeta';
import { pageMeta } from '../../pageMeta';
import { translations } from '../../localization';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';

function CoachesView() {
  const language = useSelector((state) => state.homeSlice.language);

  return (
    <div className="coaches-view-wrap">
      <PageMeta {...pageMeta['CoachesView']} />
      <div className="home-view__main-wrap">
        <img
          className="home-view__main-bg"
          src={coachesBg}
          alt="img"
        />
        <div className="home-view__main-title-wrap">
          <div className="home-view__main-title">{translations['beLeader'][language]}</div>
          <div className="home-view__main-title">{translations['forMore'][language]}</div>
          <div className="home-view__main-sub-title">{translations['weGiveYou'][language]}</div>
        </div>
        <button className="home-view__main-btn">{translations['joinUs'][language]}</button>
      </div>

      <div className="coaches-view container">
        <div className="products-view__info">
          <div className="coaches-view__info-title">{translations['desktop'][language]}</div>
          {/* <img 
                        className='products-view__info-img coaches-view__info-img' 
                        src={aboutImageOfPeopleDes} 
                        alt='img'
                    /> */}
          <ReactPlayer
            className="coaches-view__info-video"
            // url={videoProduct1}
            url={aboutImageOfPeopleDes}
            controls={false}
            playing={true}
            loop={true}
            muted={true}
          />
          <div className="products-view__info-text">{translations['proCoachDesktop'][language]}</div>
        </div>
        <div className="products-view__info">
          <div className="coaches-view__info-title">{translations['mobile'][language]}</div>
          {/* <img 
                        className='products-view__info-img' 
                        src={prtSc1} 
                        alt='img'
                    /> */}
          <ReactPlayer
            className="products-view__info-video"
            // url={videoProduct1}
            url={prtSc2}
            controls={false}
            // controls={true}
            playing={true}
            loop={true}
            muted={true}
          />
          <div className="products-view__info-text">{translations['takeYourTraining'][language]}</div>
        </div>

        <div className="coaches-view__comments-wrap">
          <CommentsBloc isCoachesView={true} />
        </div>
      </div>
    </div>
  );
}

export default CoachesView;
