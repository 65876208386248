import './LayoutUser.css';
import { useEffect, useMemo, useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../assets/logo.svg';
import exitImg from '../assets/exitImg.svg';
import logoMini from '../assets/logoMini.svg';
import AdminHeader from '../components/AdminHeader/AdminHeader';
import { setCoach, setIsModalFinished, setIsModalStripeRegistration, setIsUnreadMessages, setLanguage, setWindowInnerWidth } from '../store/userSlice';
import AdminFooter from '../components/AdminFooter/AdminFooter';
import { translations } from '../localization';
import { fetchGetData, fetchRequest, handleCheckNewEmailTime, handleCheckNewMessages, handleExit, handleGoStripe, handleUpdateEmail, handleUpdatePassword } from '../helpers/Utils';
import PreloaderCustom from '../components/PreloaderCustom/PreloaderCustom';
import ModalWindow from '../components/ModalWindow/ModalWindow';
import ConfettiFinish from '../components/ConfettiFinish/ConfettiFinish';
import dotImg from '../assets/dotImg.svg';
import envelopeImage from '../assets/envelopeImage.svg';
import finish from '../assets/finish.svg';
import { setIs_app, setShowMessageObj } from '../store/homeSlice';
import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import TextInput from '../components/TextInput/TextInput';
import { COMPANY, INDIVIDUAL, stripeCountries } from '../helpers/Config';

function LayoutUser() {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const tokenSearchParams = newSearchParams.get('token');
  const is_passwordSearchParams = newSearchParams.get('is_password');
  const is_emailSearchParams = newSearchParams.get('is_email');
  const stripe_activatedSearchParams = newSearchParams.get('stripe_activated');
  const coach_idSearchParams = newSearchParams.get('coach_id');
  const language = useSelector((state) => state.userSlice.language);
  const isPreloaderSearch = useSelector((state) => state.userSlice.isPreloaderSearch);
  const isModalFinished = useSelector((state) => state.userSlice.isModalFinished);
  const is_app = useSelector((state) => state.homeSlice.is_app);
  const coach = useSelector((state) => state.userSlice.coach);
  const isModalStripeRegistration = useSelector((state) => state.userSlice.isModalStripeRegistration);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isStripeActivated, setIsStripeActivated] = useState(false);
  const [isModalSetStripeData, setIsModalSetStripeData] = useState(false);
  const [business_type, setBusiness_type] = useState(INDIVIDUAL);
  const [business_country, setBusiness_country] = useState('');
  const [business_name, setBusiness_name] = useState('');
  const [isOpenBusinessType, setIsOpenBusinessType] = useState(false);
  const [isOpenBusinessCountry, setIsOpenBusinessCountry] = useState(false);
  const [isErrorBusiness_country, setIsErrorBusiness_country] = useState(false);
  const [isErrorBusiness_name, setIsErrorBusiness_name] = useState(false);
  const [isSendForm, setIsSendForm] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let token = localStorage.getItem('token-admin');
  const { coachId } = useParams();

  useEffect(() => {
    if (token?.length || tokenSearchParams?.length) {
      token = tokenSearchParams?.length ? tokenSearchParams : token;
      verifyToken(token);
    } else {
      navigate('/login');
    }
    
    if (stripe_activatedSearchParams?.length && tokenSearchParams?.length && coach_idSearchParams?.length) {
      dispatch(setIsModalFinished(true));
      setIsStripeActivated(true);
      verifyToken(token);
    }

    if (is_passwordSearchParams?.length && tokenSearchParams?.length) {
      dispatch(setIsModalFinished(true));
      setIsPassword(true);
      handleUpdatePassword(tokenSearchParams, dispatch, language);
    }

    if (is_emailSearchParams?.length && tokenSearchParams?.length) {
      dispatch(setIsModalFinished(true));
      setIsEmail(true);
      handleUpdateEmail(tokenSearchParams, dispatch, translations, language);
    }

    let newEmailTime = localStorage.getItem('newEmailTime');
    if (newEmailTime?.length && handleCheckNewEmailTime(JSON.parse(newEmailTime))) {
      localStorage.removeItem('newEmail');
      localStorage.removeItem('newEmailTime');
    }

    window.addEventListener('resize', handleResize);
    localStorage.getItem('localLanguage')?.length ? dispatch(setLanguage(localStorage.getItem('localLanguage'))) : dispatch(setLanguage('en'));

    handleCheckNewMessages(token, (res) => dispatch(setIsUnreadMessages(res)));
    const interval = setInterval(() => {
      handleCheckNewMessages(token, (res) => dispatch(setIsUnreadMessages(res)));
    }, 240000);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(interval);
    };
  }, []);

  const handleResize = () => {
    dispatch(setWindowInnerWidth(window.innerWidth));
  };

  const verifyToken = async (token = null) => {
    if (token) {
      fetchGetData(`/auth/verify?token=${token}`).then((res) => {
        if (res?.success && res.data?._id?.length && res.data?.is_coach) {
          dispatch(setCoach(res.data));
          dispatch(setIs_app(res.is_app));
          localStorage.setItem('token-admin', token);
          if (res.data?.is_google_auth && !res.data?.business_type?.length) {
            setIsModalSetStripeData(true);
          }
        } else {
          handleExit(res?.is_app, true);
        }
      });
    } else {
      handleExit(false, true);
    }
  };

  const handleIsActive = useMemo(() => {
    return (path) => {
      if (location.pathname.startsWith(`/auth/${coachId}/users`) && `/auth/${coachId}/users` === path) {
        return true;
      }
      if (location.pathname.startsWith(`/auth/${coachId}/notifications`) && `/auth/${coachId}/notifications` === path) {
        return true;
      }
      if (location.pathname.startsWith(`/auth/${coachId}/billing`) && `/auth/${coachId}/billing` === path) {
        return true;
      }
      if ((location.pathname.startsWith(`/auth/${coachId}/trainings`) || location.pathname.startsWith(`/auth/${coachId}/plans`)) && `/auth/${coachId}/trainings` === path) {
        return true;
      }
      return false;
    };
  }, [location.pathname]);

  const handleStripeRegistration = (boolean) => {
    if (boolean) {
      handleGoStripe(coach, token, is_app, dispatch, language);
    }
    dispatch(setIsModalStripeRegistration(false));
  };

  const handleCloseModalFinished = () => {
    dispatch(setIsModalFinished(false));
    setIsPassword(false);
    setIsEmail(false);
    const newSearchParams = new URLSearchParams();
    setSearchParams(newSearchParams);
  };

  const handleSetStripeData = (boolean) => {
    if (boolean) {
      let test1 = business_country?.length ? true : false;
      let test2 = business_type === INDIVIDUAL || business_name?.length ? true : false;
      if (test1 && test2) {
        setIsSendForm(true);
        let data = {
          email: coach.email.trim(),
          business_type: business_type,
          business_name: business_type === INDIVIDUAL ? '' : business_name,
          country: business_country,
        };

        fetchRequest('POST', `/auth/create-account-stripe`, data).then((res) => {
          if (res?.success) {
            setIsModalSetStripeData(false);
          } else {
            dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['sorryWrong'][language] }));
          }
          setIsSendForm(false);
        });
      } else {
        setIsErrorBusiness_country(!test1 && true);
        setIsErrorBusiness_name(!test2 && true);
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['mandatoryFieldsRegister'][language] }));
      }
    }
  };

  return (
    <div className="layout-user--wrpa">
      {isModalFinished && (
        <ModalWindow
          title={''}
          handleClick={handleCloseModalFinished}
          notBtn={true}
        >
          <img
            className="time-progress__modal-img"
            src={isPassword ? dotImg : isEmail ? envelopeImage : finish}
            alt="img"
          />
          <h3 className="time-progress__modal-title">{isPassword ? translations['passwordUpdated'][language] : isEmail ? translations['emailUpdated'][language] : translations['congratulation'][language]}</h3>
          <div className="time-progress__modal-text">
            {isStripeActivated && (
              <div className="time-progress__modal-text">
                <span>{translations['registrationStripe'][language]}</span>
              </div>
            )}
          </div>
          <div className="time-progress__modal-btn-wrap">
            <button
              className="mainBtnDark mainBtn--whidth"
              onClick={handleCloseModalFinished}
            >
              {translations['close'][language]}
            </button>
          </div>
          <ConfettiFinish />
        </ModalWindow>
      )}
      {isModalStripeRegistration && (
        <ModalWindow
          title={''}
          handleClick={handleStripeRegistration}
          rightBtn={translations['goToStripe'][language]}
        >
          <div className="layout-user__modal-registration-text">{translations['needRegisterStripe'][language]}</div>
        </ModalWindow>
      )}
      {isModalSetStripeData && (
        <ModalWindow
          title={''}
          handleClick={handleSetStripeData}
          notBtn={true}
          addStyles={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            maxWidth: 'none',
            height: '100%',
            borderRadius: '0',
          }}
        >
          <Stack
            spacing={3}
            display={'flex'}
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '100%',
              maxWidth: '600px',
            }}
          >
            <div className="layout-user__modal-registration-stripe-title">{translations['stripeConfig'][language]}</div>
            <FormControl fullWidth>
              <InputLabel id="business-type__label">{translations['businessType'][language]}</InputLabel>
              <Select
                open={isOpenBusinessType}
                onOpen={() => setIsOpenBusinessType(true)}
                onClose={() => setIsOpenBusinessType(false)}
                labelId="business-type__label"
                value={business_type}
                label={translations['businessType'][language]}
                onChange={(e) => setBusiness_type(e.target.value)}
                className={`sign-up__form-business-type-select ${isOpenBusinessType ? 'mu-component-is-active' : ''}`}
                MenuProps={{
                  classes: {
                    root: 'select-exercise__select',
                  },
                }}
              >
                <MenuItem
                  value={INDIVIDUAL}
                  className="sign-up__form-business-type-item"
                >
                  {translations['individualBusiness'][language]}
                </MenuItem>
                <MenuItem
                  value={COMPANY}
                  className="sign-up__form-business-type-item"
                >
                  {translations['corporationBusiness'][language]}
                </MenuItem>
              </Select>
            </FormControl>
            {business_type === COMPANY && (
              <TextInput
                setValue={setBusiness_name}
                value={business_name}
                label={translations['businessName'][language]}
                helperText={isErrorBusiness_name && business_type === COMPANY ? translations['fieldRequired'][language] : ''}
              />
            )}
            <FormControl fullWidth>
              <InputLabel id="business-country__label">{translations['businessCountry'][language]}</InputLabel>
              <Select
                open={isOpenBusinessCountry}
                onOpen={() => setIsOpenBusinessCountry(true)}
                onClose={() => setIsOpenBusinessCountry(false)}
                labelId="business-country__label"
                value={business_country}
                renderValue={business_country ? (value) => stripeCountries.find((el) => el.value === value)?.name : ''}
                label={translations['businessCountry'][language]}
                onChange={(e) => setBusiness_country(e.target.value)}
                className={`sign-up__form-business-type-select ${isOpenBusinessCountry ? 'mu-component-is-active' : ''}`}
                MenuProps={{
                  classes: {
                    root: 'select-exercise__select',
                  },
                }}
              >
                <MenuItem
                  value={''}
                  disabled
                >
                  {translations['businessCountry'][language]}
                </MenuItem>
                {!!stripeCountries.length &&
                  stripeCountries.map((el, i) => (
                    <MenuItem
                      key={i}
                      value={el.value}
                      className="sign-up__form-business-type-item"
                    >
                      {el.name}
                    </MenuItem>
                  ))}
              </Select>
              {isErrorBusiness_country && <div className="sign-up__form-phone-error">{translations['fieldRequired'][language]}</div>}
            </FormControl>
            <button
              className="mainBtnDark layout-user__modal-registration-stripe-btn"
              onClick={() => handleSetStripeData(true)}
            >
              {isSendForm ? <span className="coaching-day-view__btn-loader"></span> : <span>{translations['save'][language]}</span>}
            </button>
          </Stack>
        </ModalWindow>
      )}
      <div className="layout-user">
        <div
          id="mySidenav"
          className={`layout-user__sidenav ${isSideNavOpen ? 'layout-user__sidenav--open' : ''}`}
        >
          <div className="layout-user__sidenav-logo-wrap">
            <img
              className={`layout-user__sidenav-logo ${isSideNavOpen ? 'layout-user__sidenav-logo--open' : ''}`}
              src={logo}
              alt="img"
            />
            <img
              className={`layout-user__sidenav-logo ${!isSideNavOpen ? 'layout-user__sidenav-logo--close' : ''}`}
              src={logoMini}
              alt="img"
            />
          </div>
          <div className="layout-user__sidenav-link-wrap">
            <NavLink
              className={`layout-user__sidenav-link ${handleIsActive(`/auth/${coachId}/trainings`) ? 'layout-user__sidenav-link--active' : ''}`}
              to={`/auth/${coachId}/trainings`}
            >
              <div className="layout-user__sidenav-link-icon-wrap">
                <svg
                  className="layout-user__sidenav-link-icon layout-user__sidenav-link-icon--width"
                  viewBox="0 0 35 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className={`layout-user__sidenav-link-icon-path ${handleIsActive(`/auth/${coachId}/trainings`) ? 'layout-user__sidenav-link-icon-path--active' : ''}`}
                    d="M27.8715 14.7184L26.1905 13.0374L27.091 12.1068L23.2487 8.26445L22.3181 9.165L20.607 7.45396L21.5076 6.52339C21.9679 6.06311 22.5382 5.83797 23.2186 5.84798C23.8991 5.85798 24.4694 6.09313 24.9297 6.55341L28.8021 10.4258C29.2623 10.8861 29.4925 11.4514 29.4925 12.1218C29.4925 12.7922 29.2623 13.3576 28.8021 13.8179L27.8715 14.7184ZM13.4627 29.1572C13.0024 29.6175 12.4371 29.8477 11.7666 29.8477C11.0962 29.8477 10.5309 29.6175 10.0706 29.1572L6.19823 25.2849C5.73795 24.8246 5.50781 24.2592 5.50781 23.5888C5.50781 22.9184 5.73795 22.3531 6.19823 21.8928L7.09879 20.9922L8.80983 22.7033L7.87926 23.6038L11.7516 27.4762L12.6522 26.5456L14.3632 28.2567L13.4627 29.1572ZM25.38 19.0711L27.091 17.36L17.9955 8.26445L16.2844 9.9755L25.38 19.0711ZM16.9748 27.4762L18.6859 25.7351L9.62033 16.6696L7.87926 18.3806L16.9748 27.4762ZM16.7947 20.4519L20.0967 17.1799L18.1756 15.2587L14.9036 18.5608L16.7947 20.4519ZM18.6859 29.1572C18.2256 29.6175 17.6552 29.8477 16.9748 29.8477C16.2944 29.8477 15.7241 29.6175 15.2638 29.1572L6.19823 20.0917C5.73795 19.6314 5.50781 19.0611 5.50781 18.3806C5.50781 17.7002 5.73795 17.1299 6.19823 16.6696L7.90928 14.9585C8.36956 14.4983 8.93491 14.2681 9.60532 14.2681C10.2757 14.2681 10.8411 14.4983 11.3014 14.9585L13.1925 16.8497L16.4945 13.5477L14.6034 11.6865C14.1431 11.2263 13.913 10.6559 13.913 9.9755C13.913 9.29508 14.1431 8.72474 14.6034 8.26445L16.3144 6.55341C16.7747 6.09313 17.34 5.86298 18.0105 5.86298C18.6809 5.86298 19.2462 6.09313 19.7065 6.55341L28.8021 15.649C29.2623 16.1093 29.4925 16.6746 29.4925 17.345C29.4925 18.0154 29.2623 18.5808 28.8021 19.041L27.091 20.7521C26.6307 21.2124 26.0604 21.4425 25.38 21.4425C24.6996 21.4425 24.1292 21.2124 23.6689 20.7521L21.8078 18.8609L18.5058 22.163L20.3969 24.0541C20.8572 24.5144 21.0873 25.0797 21.0873 25.7502C21.0873 26.4206 20.8572 26.9859 20.3969 27.4462L18.6859 29.1572Z"
                  />
                </svg>
              </div>
              <div className={`layout-user__sidenav-link-text ${isSideNavOpen ? '' : 'layout-user__sidenav-link-text--close'}`}>{translations['trainings'][language]}</div>
            </NavLink>
            <NavLink
              className={`layout-user__sidenav-link ${handleIsActive(`/auth/${coachId}/users`) ? 'layout-user__sidenav-link--active' : ''}`}
              to={`/auth/${coachId}/users`}
            >
              <div className="layout-user__sidenav-link-icon-wrap">
                <svg
                  className="layout-user__sidenav-link-icon layout-user__sidenav-link-icon--width"
                  viewBox="0 0 35 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className={`layout-user__sidenav-link-icon-path ${handleIsActive(`/auth/${coachId}/users`) ? 'layout-user__sidenav-link-icon-path--active' : ''}`}
                    d="M17.5 17.8477C15.85 17.8477 14.4375 17.2602 13.2625 16.0852C12.0875 14.9102 11.5 13.4977 11.5 11.8477C11.5 10.1977 12.0875 8.78516 13.2625 7.61016C14.4375 6.43516 15.85 5.84766 17.5 5.84766C19.15 5.84766 20.5625 6.43516 21.7375 7.61016C22.9125 8.78516 23.5 10.1977 23.5 11.8477C23.5 13.4977 22.9125 14.9102 21.7375 16.0852C20.5625 17.2602 19.15 17.8477 17.5 17.8477ZM5.5 29.8477V25.6477C5.5 24.7977 5.71875 24.0164 6.15625 23.3039C6.59375 22.5914 7.175 22.0477 7.9 21.6727C9.45 20.8977 11.025 20.3164 12.625 19.9289C14.225 19.5414 15.85 19.3477 17.5 19.3477C19.15 19.3477 20.775 19.5414 22.375 19.9289C23.975 20.3164 25.55 20.8977 27.1 21.6727C27.825 22.0477 28.4062 22.5914 28.8438 23.3039C29.2813 24.0164 29.5 24.7977 29.5 25.6477V29.8477H5.5ZM8.5 26.8477H26.5V25.6477C26.5 25.3727 26.4313 25.1227 26.2938 24.8977C26.1562 24.6727 25.975 24.4977 25.75 24.3727C24.4 23.6977 23.0375 23.1914 21.6625 22.8539C20.2875 22.5164 18.9 22.3477 17.5 22.3477C16.1 22.3477 14.7125 22.5164 13.3375 22.8539C11.9625 23.1914 10.6 23.6977 9.25 24.3727C9.025 24.4977 8.84375 24.6727 8.70625 24.8977C8.56875 25.1227 8.5 25.3727 8.5 25.6477V26.8477ZM17.5 14.8477C18.325 14.8477 19.0313 14.5539 19.6188 13.9664C20.2063 13.3789 20.5 12.6727 20.5 11.8477C20.5 11.0227 20.2063 10.3164 19.6188 9.72891C19.0313 9.14141 18.325 8.84766 17.5 8.84766C16.675 8.84766 15.9688 9.14141 15.3813 9.72891C14.7938 10.3164 14.5 11.0227 14.5 11.8477C14.5 12.6727 14.7938 13.3789 15.3813 13.9664C15.9688 14.5539 16.675 14.8477 17.5 14.8477Z"
                  />
                </svg>
              </div>
              <div className={`layout-user__sidenav-link-text ${isSideNavOpen ? '' : 'layout-user__sidenav-link-text--close'}`}>{translations['bodybuilder'][language]}</div>
            </NavLink>
            <NavLink
              className={`layout-user__sidenav-link ${handleIsActive(`/auth/${coachId}/billing`) ? 'layout-user__sidenav-link--active' : ''}`}
              to={`/auth/${coachId}/billing`}
            >
              <div className="layout-user__sidenav-link-icon-wrap">
                <svg
                  className="layout-user__sidenav-link-icon layout-user__sidenav-link-icon--width"
                  viewBox="0 0 35 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_4840_1758"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="35"
                    height="36"
                  >
                    <rect
                      y="0.347656"
                      width="35"
                      height="35"
                      fill="#D9D9D9"
                    />
                  </mask>
                  <g mask="url(#mask0_4840_1758)">
                    <path
                      className={`layout-user__sidenav-link-icon-path ${handleIsActive(`/auth/${coachId}/billing`) ? 'layout-user__sidenav-link-icon-path--active' : ''}`}
                      d="M7.29167 30.9727C6.48958 30.9727 5.80295 30.6871 5.23177 30.1159C4.66059 29.5447 4.375 28.8581 4.375 28.056V7.63932C4.375 6.83724 4.66059 6.15061 5.23177 5.57943C5.80295 5.00825 6.48958 4.72266 7.29167 4.72266H27.7083C28.5104 4.72266 29.1971 5.00825 29.7682 5.57943C30.3394 6.15061 30.625 6.83724 30.625 7.63932V11.2852H27.7083V7.63932H7.29167V28.056H27.7083V24.4102H30.625V28.056C30.625 28.8581 30.3394 29.5447 29.7682 30.1159C29.1971 30.6871 28.5104 30.9727 27.7083 30.9727H7.29167ZM18.9583 25.1393C18.1562 25.1393 17.4696 24.8537 16.8984 24.2826C16.3273 23.7114 16.0417 23.0247 16.0417 22.2227V13.4727C16.0417 12.6706 16.3273 11.9839 16.8984 11.4128C17.4696 10.8416 18.1562 10.556 18.9583 10.556H29.1667C29.9688 10.556 30.6554 10.8416 31.2266 11.4128C31.7977 11.9839 32.0833 12.6706 32.0833 13.4727V22.2227C32.0833 23.0247 31.7977 23.7114 31.2266 24.2826C30.6554 24.8537 29.9688 25.1393 29.1667 25.1393H18.9583ZM29.1667 22.2227V13.4727H18.9583V22.2227H29.1667ZM23.3333 20.0352C23.941 20.0352 24.4575 19.8225 24.8828 19.3971C25.3082 18.9718 25.5208 18.4553 25.5208 17.8477C25.5208 17.24 25.3082 16.7235 24.8828 16.2982C24.4575 15.8728 23.941 15.6602 23.3333 15.6602C22.7257 15.6602 22.2092 15.8728 21.7839 16.2982C21.3585 16.7235 21.1458 17.24 21.1458 17.8477C21.1458 18.4553 21.3585 18.9718 21.7839 19.3971C22.2092 19.8225 22.7257 20.0352 23.3333 20.0352Z"
                      fill="#0A1135"
                    />
                  </g>
                </svg>
              </div>
              <div className={`layout-user__sidenav-link-text ${isSideNavOpen ? '' : 'layout-user__sidenav-link-text--close'}`}>{translations['billing'][language]}</div>
            </NavLink>
            <NavLink
              className={`layout-user__sidenav-link ${handleIsActive(`/auth/${coachId}/notifications`) ? 'layout-user__sidenav-link--active' : ''}`}
              to={`/auth/${coachId}/notifications`}
            >
              <div className="layout-user__sidenav-link-icon-wrap">
                <svg
                  className="layout-user__sidenav-link-icon layout-user__sidenav-link-icon--width"
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="36"
                  viewBox="0 0 35 36"
                  fill="none"
                >
                  <path
                    className={`layout-user__sidenav-link-icon-path ${handleIsActive(`/auth/${coachId}/notifications`) ? 'layout-user__sidenav-link-icon-path--active' : ''}`}
                    d="M7.29036 28.292C6.87717 28.292 6.53082 28.1523 6.2513 27.8728C5.97179 27.5932 5.83203 27.2469 5.83203 26.8337C5.83203 26.4205 5.97179 26.0742 6.2513 25.7946C6.53082 25.5151 6.87717 25.3754 7.29036 25.3754H8.7487V15.167C8.7487 13.1497 9.35634 11.3571 10.5716 9.78943C11.7869 8.22172 13.3668 7.19481 15.3112 6.7087V5.68787C15.3112 5.08023 15.5239 4.56373 15.9492 4.13839C16.3746 3.71304 16.8911 3.50037 17.4987 3.50037C18.1063 3.50037 18.6228 3.71304 19.0482 4.13839C19.4735 4.56373 19.6862 5.08023 19.6862 5.68787V6.7087C21.6306 7.19481 23.2105 8.22172 24.4258 9.78943C25.6411 11.3571 26.2487 13.1497 26.2487 15.167V25.3754H27.707C28.1202 25.3754 28.4666 25.5151 28.7461 25.7946C29.0256 26.0742 29.1654 26.4205 29.1654 26.8337C29.1654 27.2469 29.0256 27.5932 28.7461 27.8728C28.4666 28.1523 28.1202 28.292 27.707 28.292H7.29036ZM17.4987 32.667C16.6966 32.667 16.01 32.3814 15.4388 31.8103C14.8676 31.2391 14.582 30.5524 14.582 29.7504H20.4154C20.4154 30.5524 20.1298 31.2391 19.5586 31.8103C18.9874 32.3814 18.3008 32.667 17.4987 32.667ZM11.6654 25.3754H23.332V15.167C23.332 13.5629 22.7609 12.1896 21.6185 11.0472C20.4761 9.90488 19.1029 9.3337 17.4987 9.3337C15.8945 9.3337 14.5213 9.90488 13.3789 11.0472C12.2365 12.1896 11.6654 13.5629 11.6654 15.167V25.3754Z"
                    fill="#0A1135"
                  />
                </svg>
              </div>
              <div className={`layout-user__sidenav-link-text ${isSideNavOpen ? '' : 'layout-user__sidenav-link-text--close'}`}>{translations['notifications'][language]}</div>
            </NavLink>
          </div>
          <div className="layout-user__sidenav-btn-open-wrap">
            <button
              className="layout-user__sidenav-link"
              onClick={() => handleExit(is_app, true)}
            >
              <div className="layout-user__sidenav-link-icon-wrap">
                <img
                  className="layout-user__sidenav-link-icon-exit"
                  src={exitImg}
                  alt="img"
                />
              </div>
              <div className={`layout-user__sidenav-link-text layout-user__sidenav-link-text--exit ${isSideNavOpen ? '' : 'layout-user__sidenav-link-text--close'}`}>{translations['logOut'][language]}</div>
            </button>
            <button
              className={`layout-user__sidenav-btn-open-menu ${isSideNavOpen ? 'layout-user__sidenav-btn-open-menu--open' : ''}`}
              onClick={() => setIsSideNavOpen(!isSideNavOpen)}
            >
              <span className="layout-user__sidenav-btn-open-span"></span>
              <span className={`layout-user__sidenav-btn-open-span layout-user__sidenav-btn-open-span--2 ${isSideNavOpen ? 'layout-user__sidenav-btn-open-span--open' : ''}`}></span>
              <span className="layout-user__sidenav-btn-open-span"></span>
            </button>
          </div>
        </div>

        <div
          id="main"
          className={`layout-user__main ${isSideNavOpen ? 'layout-user__main--open' : ''}`}
        >
          <AdminHeader />
          {isPreloaderSearch && <PreloaderCustom newStyle={{ top: '95px', marginLeft: '0' }} />}
          <Outlet />
          <AdminFooter />
        </div>
      </div>
    </div>
  );
}

export default LayoutUser;
