import './TrainingView.css';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { fetchGetData } from '../../helpers/Utils';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import noPhotos from '../../assets/noPhotos.svg';
import qrcode from '../../assets/qrcode.svg';
import shareImg from '../../assets/shareImg.svg';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import QRCode from 'qrcode.react';
import TextInput from '../../components/TextInput/TextInput';
import { translations } from '../../localization';

function TrainingView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const languageSearchParams = searchParams.get('language');
  const [training, setTraining] = useState({});
  const [isPreloader, setIsPreloader] = useState(false);
  const [isOpenQr, setIsOpenQr] = useState(false);
  const [isEmailModal, setIsEmailModal] = useState(false);
  const [email, setEmail] = useState('');
  const [language, setLanguage] = useState(languageSearchParams?.length ? languageSearchParams : 'en');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idTraining } = useParams();

  useEffect(() => {
    if (idTraining?.length) {
      handleGetTraining();
    } else {
      navigate('/login');
    }
  }, []);

  const handleAddTrening = () => {
    let res = localStorage.getItem('localAddTraining');
    if (res?.length) {
      let resArr = JSON.parse(res);
      localStorage.setItem('localAddTraining', JSON.stringify([...resArr, { training_id: training._id, coach_id: training.coach_id }]));
    } else {
      localStorage.setItem('localAddTraining', JSON.stringify([{ training_id: training._id, coach_id: training.coach_id }]));
    }
    navigate('/login');
  };

  const handleGetTotalTime = () => {
    if (training?.days?.length) {
      let res = 0;
      training.days.forEach((el) => {
        if (el.exercises?.length) {
          res += el.exercises.reduce((acc, el) => acc + el.time, 0);
        }
      });
      return res;
    }
  };

  const handleGetTraining = () => {
    setIsPreloader(true);
    fetchGetData(`/trainings/${idTraining}`).then((res) => {
      if (res?.success && res?.data) {
        setTraining(res.data);
      } else {
        navigate('/login');
      }
      setIsPreloader(false);
    });
  };

  return (
    <div className="training-view-wrap">
      {isPreloader && (
        <PreloaderCustom
          newStyle={{ height: `100vh`, left: `0`, top: '0' }}
          isNewStyleLoader={true}
        />
      )}
      {isOpenQr && (
        <ModalWindow
          title={''}
          handleClick={() => setIsOpenQr(false)}
          notBtn={true}
        >
          {!!training._id?.length && (
            <div className="admin-trainings__dialogform-qr-wrap">
              <QRCode
                value={training._id}
                size={260}
              />
            </div>
          )}
        </ModalWindow>
      )}
      {isEmailModal && (
        <ModalWindow
          title={'Введіть ваш E-mail'}
          handleClick={() => setIsEmailModal(false)}
          rightBtn="Надіслати"
          leftBtn="Відмінити"
        >
          <TextInput
            setValue={setEmail}
            value={email}
            label={'E-mail'}
          />
        </ModalWindow>
      )}
      <div className="training-view container">
        <img
          className="training-view__img"
          src={training?.image?.length ? training?.image : noPhotos}
          alt="img"
        />
        <div className="training-view__info">
          <div className="training-view__title">{training?.name}</div>
          <div className="training-view__content">
            <div className="training-view__content-title">{translations['coach'][language]}:</div>
            <div className="training-view__content-text">{training.coach}</div>
          </div>
          <div className="training-view__content">
            <div className="training-view__content-title">{translations['daysAmount'][language]}:</div>
            <div className="training-view__content-text">{training.days?.length}</div>
          </div>
          <div className="training-view__content">
            <div className="training-view__content-title">{translations['numberMinutes'][language]}:</div>
            <div className="training-view__content-text">{handleGetTotalTime()}</div>
          </div>
          <div className="training-view__content">
            <div className="training-view__content-title">{translations['price'][language]}:</div>
            <div className="training-view__content-text">${training.price}</div>
          </div>
          <div className="training-view__add-wrap">
            <div>{translations['addTrainingMethods'][language]}</div>
            <button
              className="training-view__add-btn"
              onClick={() => setIsOpenQr(true)}
            >
              <span className="training-view__add-btn-text">1. {translations['addTrainingMethods1'][language]}</span>
              <img
                className="training-view__add-btn-img"
                src={qrcode}
                alt="img"
              />
            </button>
            <button
              className="training-view__add-btn"
              onClick={() => setIsEmailModal(true)}
            >
              <span className="training-view__add-btn-text">2. {translations['addTrainingMethods2'][language]}</span>
              <img
                className="training-view__add-btn-img"
                src={shareImg}
                alt="img"
              />
            </button>
            {/* <button className='training-view__add-btn' onClick={handleAddTrening}>
                            <span className='training-view__add-btn-text'>3. Натисніть кнопку добавити і залогінитись</span>
                            <img className='training-view__add-btn-img' src={plusImg} alt='img'/>
                        </button> */}
            <button
              className="mainBtnWhite"
              onClick={() => navigate('/login')}
            >
              {translations['login'][language]}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainingView;
