import './LoadingHasMore.css';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import { Typography } from '@mui/material';

function LoadingHasMore({isBodybuilder = false}) {
  const languageHome = useSelector((state) => state.homeSlice.language);
  const languageUser = useSelector((state) => state.userSlice.language);

  return (
    <Typography
      variant="h6"
      textAlign={'center'}
      m={2}
    >
      {translations['loading'][ isBodybuilder ? languageHome : languageUser]}
    </Typography>
  );
}

export default memo(LoadingHasMore);
