import './NotFoundView.css';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import notFoundImage from '../../assets/notFoundImage.png';
import { useNavigate } from 'react-router';

function NotFoundView({ isLayoutHome = false, isLayoutUser = false }) {
  const homeLanguage = useSelector((state) => state.homeSlice.language);
  const userLanguage = useSelector((state) => state.userSlice.language);
  const coach = useSelector((state) => state.userSlice.coach);
  const navigate = useNavigate();

  return (
    <div className={`not-found ${isLayoutHome ? 'not-found--home' : ''}`}>
      <PageMeta {...pageMeta['NotFoundView']} />

      <div className="not-found--wrap container">
        <div className="not-found__main">
          <img
            className="not-found__main-img"
            src={notFoundImage}
            alt="img"
          />
          {isLayoutHome && (
            <button
              className="mainBtnDark"
              onClick={() => navigate('/')}
            >
              Home page
            </button>
          )}
          {isLayoutUser && (
            <button
              className="mainBtnDark"
              onClick={() => navigate(`/auth/${coach._id}/trainings`)}
            >
              {translations['goToTraining'][userLanguage]}
            </button>
          )}
          {!isLayoutHome && !isLayoutUser && (
            <button
              className="mainBtnDark"
              onClick={() => navigate('-1')}
            >
              {translations['goBack'][homeLanguage]}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default NotFoundView;
