import './InfoBlock.css';
import { memo } from 'react';
import infoImg1 from '../../assets/infoImg1.png';
import infoImg2 from '../../assets/infoImg2.png';
import infoImg3 from '../../assets/infoImg3.png';
import aboutLogo from '../../assets/aboutLogo.svg';

function InfoBlock({ title, number, text, btnText, index, isAboutView = false }) {
  const infoImgs = [infoImg1, infoImg2, infoImg3];
  const infoImg = infoImgs[index];

  return (
    <div className="info-block">
      <div className="info-block__img-wrap">
        <img
          className={`info-block__img ${isAboutView ? 'info-block__img--about' : ''}`}
          src={isAboutView ? aboutLogo : infoImg}
          alt="img"
        />
      </div>
      <div className="info-block__main">
        <div className="info-block__main-title">{title}</div>
        <div className={`info-block__main-number ${index === 2 && 'info-block__main-number--left'} ${isAboutView && 'info-block__main-number--about'}`}>{number}</div>
        <div className="info-block__main-text">{text}</div>
        <button className="info-block__main-btn">{btnText}</button>
      </div>
    </div>
  );
}

export default memo(InfoBlock);
