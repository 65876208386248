import './TimeProgress.css';
import { NavLink, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useState, useEffect, memo } from 'react';
import squareInst from '../../assets/squareInst.svg';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import { useDispatch } from 'react-redux';
import { setIsModalFinished, setShowMessageObj, setUserTraining } from '../../store/homeSlice';
import { fetchRequest } from '../../helpers/Utils';

function TimeProgress() {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const activeNumber_setsSearchParams = JSON.parse(searchParams.get('activeNumber_sets'));
  const language = useSelector((state) => state.homeSlice.language);
  const userExercise = useSelector((state) => state.homeSlice.userExercise);
  const user = useSelector((state) => state.homeSlice.user);
  const userTraining = useSelector((state) => state.homeSlice.userTraining);
  const [activeNumber_sets, setActiveNumber_sets] = useState(activeNumber_setsSearchParams ? activeNumber_setsSearchParams : 1);
  const [number_sets, setNumber_sets] = useState(userExercise.number_sets);
  const [number_repetitions, setNumber_repetitions] = useState(userExercise.number_repetitions);
  const [time, setTime] = useState(0);
  const [totalTime, setTotalTime] = useState(userExercise.time * 60);
  const [allNumber_repetitions, setAllNumber_repetitions] = useState(number_repetitions);
  const [leftSets, setLeftSets] = useState(number_repetitions);
  const [isFinishedDay, setIsFinishedDay] = useState(false);
  const [isFinishedExercise, setIsFinishedExercise] = useState(false);
  const [isBtnLoader, setIsBtnLoader] = useState(false);
  const [timerProgress, setTimerProgress] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { coachingDayId } = useParams();
  const { coachingId } = useParams();
  const { coachingPlanId } = useParams();
  let token = localStorage.getItem('token-user');

  useEffect(() => {
    if (userTraining?._id?.length) {
      let res = localStorage.getItem('localExercisesLocation');
      let resArr = JSON.parse(res);
      if (resArr?.length) {
        let resObj = resArr.find((el) => el.pathname === location.pathname);
        if (resObj?.pathname?.length) {
          const elapsedTime = parseInt(parseInt(Date.now() - parseInt(resObj.time)) / 1000);
          if (elapsedTime < totalTime && resObj.time !== 0) {
            setTime(elapsedTime);
            const progress = (elapsedTime / totalTime) * 100;
            setTimerProgress(progress);
            const decrementInterval = totalTime / number_repetitions;
            const decrementTime = Math.floor(elapsedTime / decrementInterval);
            setLeftSets(number_repetitions - 1 - decrementTime);
            setIsActive(true);
          }
          if (elapsedTime > totalTime) {
            setIsActive(false);
            handleFinishedTimer();
            if (resObj.activeNumber_sets === number_sets && ((resObj.finishNumber_sets && resObj.finishNumber_sets === resObj.activeNumber_sets) || !resObj.finishNumber_sets)) {
              handleCheckingFinishedDay();
            }
          }
        }
      }
    }
  }, [userTraining]);

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        if (totalTime > time) {
          setTime((prevTime) => prevTime + 1);
          const progress = (time / totalTime) * 100;
          setTimerProgress(progress);
          const decrementInterval = totalTime / number_repetitions;
          const decrementTime = Math.floor(time / decrementInterval);
          setLeftSets(number_repetitions - 1 - decrementTime);

          if (totalTime === time + 1 && activeNumber_sets === number_sets) {
            handleCheckingFinishedDay();
          }
        } else {
          setIsActive(false);
          clearInterval(interval);
          handleFinishedTimer();
        }
      }, 1000);
    } else if (!isActive && time !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, time]);

  const startTimer = () => {
    setIsActive(true);

    let res = localStorage.getItem('localExercisesLocation');
    if (res?.length) {
      let resArr = JSON.parse(res);
      if (resArr.filter((el) => el.pathname === location.pathname).length) {
        localStorage.setItem('localExercisesLocation', JSON.stringify([...resArr.map((el) => (el.pathname === location.pathname ? { pathname: location.pathname, activeNumber_sets: activeNumber_sets, time: new Date().getTime() } : el))]));
      } else {
        localStorage.setItem('localExercisesLocation', JSON.stringify([{ pathname: location.pathname, activeNumber_sets: activeNumber_sets, time: new Date().getTime() }, ...resArr]));
      }
    } else {
      localStorage.setItem('localExercisesLocation', JSON.stringify([{ pathname: location.pathname, activeNumber_sets: activeNumber_sets, time: new Date().getTime() }]));
    }
  };

  const handleFinishedTimer = () => {
    let res = localStorage.getItem('localExercisesLocation');
    let resArr = JSON.parse(res);
    if (resArr?.length) {
      resArr = resArr.map((el) => {
        if (el.pathname === location.pathname) {
          if (el.finishNumber_sets && (el.finishNumber_sets + 1 == el.activeNumber_sets || el.activeNumber_sets == number_sets)) {
            return el;
          } else {
            setActiveNumber_sets(el.activeNumber_sets + 1 >= number_sets ? number_sets : el.activeNumber_sets + 1);
            newSearchParams.set('activeNumber_sets', JSON.stringify(el.activeNumber_sets + 1 >= number_sets ? number_sets : el.activeNumber_sets + 1));
            setSearchParams(newSearchParams);
            return { ...el, finishNumber_sets: activeNumber_sets, activeNumber_sets: activeNumber_sets === number_sets ? number_sets : activeNumber_sets + 1, time: 0 };
          }
        } else {
          return el;
        }
      });
      localStorage.setItem('localExercisesLocation', JSON.stringify(resArr));
    }
  };

  const formatTime = (seconds, isTotalTime = false) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    const formattedHours = hours > 0 ? `${hours < 10 ? '0' + hours : hours}` : userExercise.time > 60 ? '00' : '';
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    if (isTotalTime) {
      return `${formattedHours > 0 ? `${formattedHours}:` : ''}${formattedMinutes}`;
    }
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    if (seconds >= 3600 && (hours === 0 || (minutes === 0 && remainingSeconds === 0))) {
      return '00:00:00';
    }
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const handleNexSet = () => {
    let res = localStorage.getItem('localExercisesLocation');
    let resArr = JSON.parse(res);
    let localActiveNumber_sets = activeNumber_setsSearchParams;
    if (resArr?.length) {
      let resNum = resArr.find((el) => el.pathname === location.pathname)?.activeNumber_sets;
      if (resNum) {
        localActiveNumber_sets = resNum;
      }
      resArr = resArr.map((el) => (el.pathname === location.pathname ? { ...el, finishNumber_sets: el.finishNumber_sets ? el.finishNumber_sets + 1 : activeNumber_sets, activeNumber_sets: activeNumber_setsSearchParams === el.activeNumber_sets ? activeNumber_sets + 1 : el.activeNumber_sets, time: 0 } : el));
      localStorage.setItem('localExercisesLocation', JSON.stringify(resArr));
    }
    setTimerProgress(0);
    setLeftSets(number_repetitions);
    setTime(0);
    setIsActive(false);
  };

  const renderProgressBars = () => {
    let bars = [];
    const barWidth = 100 / number_repetitions;

    for (let i = 0; i < number_repetitions; i++) {
      const isBarFilled = i * barWidth < timerProgress;
      const barClass = isBarFilled ? 'progress-bar filled' : 'progress-bar';

      bars.push(
        <div
          key={`bar-${i}`}
          className={barClass}
          style={{ width: `${barWidth}%` }}
        ></div>,
      );

      if (isBarFilled && i < number_repetitions - 1) {
        bars.push(
          <div
            key={`separator-${i}`}
            className="separator"
          ></div>,
        );
      }
    }

    return bars;
  };

  const handleFinishedDay = () => {
    if (userTraining._id?.length) {
      // логіка закінчення дня (доробити запит на бек)
      setIsBtnLoader(true);
      const index = userTraining.days.findIndex((element) => element._id === coachingDayId);
      let test = index !== -1 && index === userTraining.days.length - 1;
      let data = {
        user_id: user._id,
        training_id: userTraining._id,
        coach_id: userTraining?.coach_id,
        assigned_id: userTraining?.assigned_id,
      };
      if (isFinishedDay && test) {
        // логіка закінчення тренування (доробити запит на бек)
        fetchRequest('POST', `/assigned/finish-training?token=${token}`, data).then((res) => {
          if (res?.success) {
            dispatch(setIsModalFinished(true));
            dispatch(setUserTraining({ ...userTraining, training_finished: true }));
            navigate(`/user/${user._id}?finished_training=${userTraining?.name}`);
            handleFilterlocalExercises();
          } else {
            dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
          }
          setIsBtnLoader(false);
        });
      } else {
        fetchRequest('POST', `/assigned/finish-day?token=${token}`, data).then((res) => {
          if (res?.success && res?.data) {
            dispatch(setIsModalFinished(true));
            dispatch(setUserTraining({ ...userTraining, active_day_id: res?.data?.active_day_id, active_exercise_id: res?.data?.active_exercise_id, active_exercise_index: res?.data.active_exercise_index, finish_day_at: res?.data?.finish_day_at }));
            navigate(`/user/${user._id}/${coachingId}?finished_day=${userTraining?.days?.length ? userTraining?.days?.find((el) => el._id === coachingDayId)?.name : ''}`);
            handleFilterlocalExercises();
          } else {
            dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
          }
          setIsBtnLoader(false);
        });
      }
    }
  };

  const handleFinished = () => {
    // логіка закінчення вправи (доробити запит на бек)
    if (userTraining._id?.length) {
      setIsBtnLoader(true);
      let data = {
        user_id: user._id,
        training_id: userTraining._id,
        coach_id: userTraining?.coach_id,
        assigned_id: userTraining?.assigned_id,
      };
      fetchRequest('POST', `/assigned/finish-exercise?token=${token}`, data).then((res) => {
        if (res?.success && res?.data) {
          handleFilterlocalExercises();
          dispatch(setUserTraining({ ...userTraining, active_exercise_id: res?.data.active_exercise_id, active_exercise_index: res?.data.active_exercise_index }));
          navigate(`/user/${user._id}/${coachingId}/${coachingDayId}/${res?.data.active_exercise_id}?activeNumber_sets=${1}`);
        } else {
          dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
        }
        setIsBtnLoader(false);
      });
    }
  };

  const handleFilterlocalExercises = () => {
    let res = localStorage.getItem('localExercisesLocation');
    if (res?.length) {
      let resArr = JSON.parse(res);
      if (resArr?.length) {
        resArr = resArr.filter((el) => el.pathname !== location.pathname);
        if (resArr?.length) {
          localStorage.setItem('localExercisesLocation', JSON.stringify([...resArr]));
        } else {
          localStorage.removeItem('localExercisesLocation');
        }
      }
    }
  };

  const handleCheckingFinishedDay = () => {
    if (userTraining?._id?.length) {
      let resDay = userTraining.days.find((el) => el._id === coachingDayId);
      if (resDay?.exercises?.length) {
        let exerciseIndex = resDay?.exercises.findIndex((el) => el._id === coachingPlanId);
        setIsFinishedDay(resDay.exercises.length - 1 === exerciseIndex ? true : false);
        setIsFinishedExercise(true);
        setTime(userExercise.time * 60);
        setTimerProgress(100);
      } else {
        setIsFinishedExercise(true);
      }
    }
  };

  return (
    <div className="time-progress-wrap">
      <div className="time-progress__name-wrap">
        <div className="time-progress__name">{userExercise.name}</div>
        <div className="time-progress__name-time-wrap">
          <span className="time-progress__name-time disabledContainer">
            <span>{formatTime(userExercise.time * 60, true)}</span>
            <span>:</span>
            <span className="time-progress__name-time-seconds">00</span>
          </span>
          <span className="time-progress__instractions-number-line"></span>
          <span className="time-progress__name-time time-progress__name-time--active">{formatTime(time)}</span>
        </div>
      </div>
      <div className="time-progress">
        <div className="time-progress__container">{renderProgressBars()}</div>
      </div>

      <div className="time-progress__instractions-wrap">
        <div className="time-progress__instractions-sets-wrap">
          <span className="time-progress__instractions-number disabledContainer">{number_sets}</span>
          <span className="time-progress__instractions-number-line"></span>
          <span className="time-progress__instractions-number">{activeNumber_sets}</span>
          <div className="time-progress__instractions-text">{translations['setsLeft'][language]}</div>
        </div>
        <div className="time-progress__instractions-number-wrap">
          <div>
            <span className="time-progress__instractions-number disabledContainer">{allNumber_repetitions}</span>
            <span className="time-progress__instractions-number-line"></span>
            <span className="time-progress__instractions-number">{leftSets}</span>
          </div>
          <span className="time-progress__instractions-text">{translations['repetitionsLeft'][language]}</span>
        </div>
        <NavLink
          className="time-progress__instractions-btn"
          to={'instractions'}
        >
          <img
            className="time-progress__instractions-btn-img"
            src={squareInst}
            alt="img"
          />
          <span className="time-progress__instractions-text">{translations['instractions'][language]}</span>
        </NavLink>
      </div>

      <div className="time-progress__btn-wrap">
        {isFinishedExercise ? (
          <>
            {isFinishedDay ? (
              <button
                className="mainBtnWhite time-progress__btn-start"
                disabled={isBtnLoader}
                onClick={handleFinishedDay}
              >
                {isBtnLoader ? <span className="coaching-day-view__btn-loader"></span> : <span>{translations['finish'][language]}</span>}
              </button>
            ) : (
              <button
                className="mainBtnDark time-progress__btn-start"
                disabled={isBtnLoader}
                onClick={handleFinished}
              >
                {isBtnLoader ? <span className="coaching-day-view__btn-loader"></span> : <span>{translations['complete'][language]}</span>}
              </button>
            )}
          </>
        ) : (
          <>
            {time >= totalTime && totalTime !== 0 ? (
              <button
                className="mainBtnDark time-progress__btn-start"
                onClick={handleNexSet}
              >
                {translations['next'][language]}
              </button>
            ) : (
              <button
                className="mainBtnDark time-progress__btn-start"
                disabled={leftSets !== number_repetitions}
                onClick={startTimer}
              >
                {translations['start'][language]}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default memo(TimeProgress);
