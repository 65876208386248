import { useEffect } from 'react';
import confetti from 'canvas-confetti';
import './ConfettiFinish.css';

const ConfettiFinish = () => {
  useEffect(() => {
    const end = Date.now() + 3 * 1000;

    const animateConfetti = () => {
      confetti({
        particleCount: 2,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: ['#bb0000', '#ffffff'],
      });
      confetti({
        particleCount: 2,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: ['#bb0000', '#ffffff'],
      });

      if (Date.now() < end) {
        requestAnimationFrame(animateConfetti);
      }
    };

    animateConfetti();

    return () => cancelAnimationFrame(animateConfetti);
  }, []);

  return null;
};

export default ConfettiFinish;
