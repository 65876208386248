import './ScanningView.css';
import ScannerCamera from '../../components/ScannerCamera/ScannerCamera';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';

function ScanningView() {
  const language = useSelector((state) => state.homeSlice.language);

  return (
    <div className="scanning-view-wrap">
      <PageMeta {...pageMeta['ScanningView']} />

      <div className="scanning-view container">
        <ScannerCamera />
        <h2 className="scanning-view__title">{translations['pointCameraQr'][language]}</h2>
      </div>
    </div>
  );
}

export default ScanningView;
