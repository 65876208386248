import './HomeView.css';
import mainBg from '../../assets/mainBg.png';
import InfoBlock from '../../components/InfoBlock/InfoBlock';
import infoMap from '../../assets/infoMap.png';
import CommentsBloc from '../../components/CommentsBloc/CommentsBloc';
import phone from '../../assets/phone.svg';
import PageMeta from '../../components/PageMeta/PageMeta';
import { pageMeta } from '../../pageMeta';
import { handleCheckApp } from '../../helpers/Utils';
import AvailableBloc from '../../components/AvailableBloc/AvailableBloc';
import { translations } from '../../localization';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

function HomeView() {
  const language = useSelector((state) => state.homeSlice.language);

  const infoArr = [
    {
      title: translations['activeBodybuilders'][language],
      number: '18000+',
      text: translations['activeBodybuildersText'][language],
      btnText: translations['joinUs'][language],
    },
    {
      title: translations['coaches'][language],
      number: '1000+',
      text: translations['becomeCoachText'][language],
      btnText: translations['becomeCoach'][language],
    },
    {
      title: translations['trainings'][language],
      number: '6800+',
      text: translations['becomeCoachText2'][language],
      btnText: translations['becomeCoach'][language],
    },
  ];

  const handleClickCheckApp = () => {
    handleCheckApp(
      'tg://resolve?domain=telegram', // iOS URL-схема
      'org.telegram.messenger', // Android package name
      'intent://resolve?domain=telegram#Intent;package=org.telegram.messenger;scheme=tg;end;', // Android URL-схема
      'https://telegram.org', // Fallback URL
    );
  };

  return (
    <div className="home-view-wrap">
      <PageMeta {...pageMeta['HomeView']} />
      <div className="home-view__main-wrap">
        <img
          className="home-view__main-bg home-view__main-bg--position-left"
          src={mainBg}
          alt="img"
        />
        <div className="home-view__main-title-wrap">
          <div className="home-view__main-title">{translations['transform'][language]}</div>
          <div className="home-view__main-sub-title">{translations['communicationBetween'][language]}</div>
        </div>
        {/* <button 
                    className='home-view__main-btn'
                    onClick={handleClickCheckApp}
                >
                    <img 
                        className='home-view__main-btn-img' 
                        src={phone} 
                        alt='img'
                    />
                    <span>{translations['getStarted'][language]}</span>
                </button> */}
        <NavLink
          className="home-view__main-btn"
          to={`/register?language=${language}`}
        >
          <img
            className="home-view__main-btn-img"
            src={phone}
            alt="img"
          />
          <span>{translations['getStarted'][language]}</span>
        </NavLink>
      </div>
      <div className="home-view container">
        <div className="home-view__info-wrap">
          {infoArr.map((item, index) => {
            return (
              <InfoBlock
                key={index}
                index={index}
                title={item.title}
                number={item.number}
                text={item.text}
                btnText={item.btnText}
              />
            );
          })}
        </div>
        <div className="home-view__available-wrap">
          <AvailableBloc />
        </div>
        <CommentsBloc isCoachesView={true} />
        <div className="home-view__map-wrap">
          <div className="home-view__map-text-wrap">
            <div className="home-view__map-text">{translations['our'][language]}</div>
            <div className="home-view__map-title">{translations['users'][language]}</div>
          </div>
          <img
            className="home-view__map-img"
            src={infoMap}
            alt="img"
          />
        </div>
      </div>
    </div>
  );
}

export default HomeView;
