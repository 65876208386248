import './NumberInput.css';
import { memo } from 'react';
import { TextField } from '@mui/material';
import { handleFocusAppInput } from '../../helpers/Utils';
import { useDispatch } from 'react-redux';
import { setIsInputFocus } from '../../store/userSlice';
import { useSelector } from 'react-redux';

function NumberInput({ value, setValue, label, newStyle = null, helperText = null }) {
  const is_app = useSelector((state) => state.homeSlice.is_app);
  const dispatch = useDispatch();

  return (
    <TextField
      className={`text-input ${value?.length ? 'mu-component-is-active' : ''}`}
      style={newStyle ? { ...newStyle } : {}}
      onChange={(e) => setValue(e.target.value)}
      onFocus={(e) => {
        if (value === 0) {
          setValue('');
        }
        handleFocusAppInput(true, (res) => dispatch(setIsInputFocus(res)), is_app);
      }}
      onBlur={() => handleFocusAppInput(false, (res) => dispatch(setIsInputFocus(res)), is_app)}
      value={value}
      inputProps={{ min: 0 }}
      label={label}
      type="number"
      variant="outlined"
      helperText={helperText}
    />
  );
}

export default memo(NumberInput);
