import './ProductsView.css';
import CommentsBloc from '../../components/CommentsBloc/CommentsBloc';
import videoProduct from '../../assets/videoProduct.mp4';
import ReactPlayer from 'react-player';
import videoProduct3 from '../../assets/videoProduct3.mp4';
// import videoProduct2 from '../../assets/videoProduct2.gif';
import videoProduct2 from '../../assets/videoProduct2.mp4';
import PageMeta from '../../components/PageMeta/PageMeta';
import { pageMeta } from '../../pageMeta';
import AvailableBloc from '../../components/AvailableBloc/AvailableBloc';
import { translations } from '../../localization';
import { useSelector } from 'react-redux';

function ProductsView() {
  const language = useSelector((state) => state.homeSlice.language);

  return (
    <div className="products-view-wrap">
      <PageMeta {...pageMeta['ProductsView']} />
      <div className="home-view__main-wrap">
        <ReactPlayer
          className="products-view__main-video"
          url={videoProduct}
          controls={false}
          playing={true}
          loop={true}
          muted={true}
        />
        <button className="home-view__main-btn">{translations['getStarted'][language]}</button>
      </div>

      <div className="products-view container">
        <div className="products-view__info">
          <div className="products-view__info-title">{translations['yourGoal'][language]}</div>
          {/* <img className='products-view__info-img' src={prtSc1} alt='img'/> */}
          <ReactPlayer
            className="products-view__info-video"
            url={videoProduct3}
            controls={false}
            playing={true}
            loop={true}
            muted={true}
          />
          <div className="products-view__info-text">{translations['createAnAccount'][language]}</div>
        </div>
        <div className="products-view__info">
          <div className="products-view__info-title">{translations['easyToGet'][language]}</div>
          {/* <img className='products-view__info-img' src={videoProduct2} alt='img'/> */}
          <ReactPlayer
            className="products-view__info-video"
            url={videoProduct2}
            controls={false}
            playing={true}
            loop={true}
            muted={true}
          />
          <div className="products-view__info-text">{translations['scanCode'][language]}</div>
        </div>
        <div className="home-view__available-wrap">
          <AvailableBloc />
        </div>
        <div className="products-view__comments-wrap">
          <CommentsBloc isProductsView={true} />
        </div>
      </div>
    </div>
  );
}

export default ProductsView;
