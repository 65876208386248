import './TextInput.css';
import { memo } from 'react';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setIsInputFocus } from '../../store/userSlice';
import { handleFocusAppInput } from '../../helpers/Utils';
import { useSelector } from 'react-redux';

function TextInput({ value, setValue, label, newStyle = null, helperText = null, rows = null, isDisabled = false, handleKeyEnter = null }) {
  const is_app = useSelector((state) => state.homeSlice.is_app);
  const dispatch = useDispatch();

  return (
    <TextField
      className={`text-input ${value?.length ? 'mu-component-is-active' : ''}`}
      style={newStyle ? { ...newStyle } : {}}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      label={label}
      variant="outlined"
      helperText={helperText}
      onKeyDown={(e) => e.key === 'Enter' && handleKeyEnter && handleKeyEnter()}
      rows={rows ? rows : 1}
      multiline={rows ? true : false}
      disabled={isDisabled}
      onFocus={() => handleFocusAppInput(true, (res) => dispatch(setIsInputFocus(res)), is_app)}
      onBlur={() => handleFocusAppInput(false, (res) => dispatch(setIsInputFocus(res)), is_app)}
    />
  );
}

export default memo(TextInput);
