import './CreateExercise.css';
import { memo, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ModalWindow from '../ModalWindow/ModalWindow';
import TextInput from '../TextInput/TextInput';
import ReactQuill from 'react-quill';
import { toolbarOptions } from '../../helpers/Config';
import close2 from '../../assets/close2.svg';
import playCircleBlack from '../../assets/playCircleBlack.svg';
import { fetchGetData, fetchRequest, handleBytesToMegabytes, handleSaveMedia, handleUploadImgCompressor, handleValidURL } from '../../helpers/Utils';
import { useSelector, useDispatch } from 'react-redux';
import { translations } from '../../localization';
import { setExerciseObj } from '../../store/userSlice';
import { setShowMessageObj } from '../../store/homeSlice';
import NumberInput from '../NumberInput/NumberInput';
import ReactPlayer from 'react-player';
import { DesktopTimePicker, LocalizationProvider, TimeField, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import UploadImage from '../UploadImage/UploadImage';
import getCroppedImg from '../../helpers/cropImage';
import Cropper from 'react-easy-crop';
import ResultImage from '../ResultImage/ResultImage';
import { Slider, TextField, Typography } from '@mui/material';

function CreateExercise({ createTab = 1, isExercise = true, setIsExercise, setIsPreloader, isPreloader }) {
  const windowInnerWidth = useSelector((state) => state.userSlice.windowInnerWidth);
  const language = useSelector((state) => state.userSlice.language);
  const exerciseObj = useSelector((state) => state.userSlice.exerciseObj);
  const coach = useSelector((state) => state.userSlice.coach);
  const errorExerciseObj = useSelector((state) => state.userSlice.errorExerciseObj);
  const [exerciseName, setExerciseName] = useState('');
  const [numberSets, setNumberSets] = useState(0);
  const [timeExercise, setTimeExercise] = useState(0);
  const [timeRest, setTimeRest] = useState(0);
  const [numberRepetitions, setNumberRepetitions] = useState(0);
  const [numberEquipment, setNumberEquipment] = useState('');
  const [mistakes, setMistakes] = useState({});
  const [newVideo, setNewVideo] = useState({});
  const [video, setVideo] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [previewLink, setPreviewLink] = useState('');
  const [imageLink, setImageLink] = useState('');
  const [newImage, setNewImage] = useState('');
  const [image, setImage] = useState('');
  const [newPreviewImage, setNewPreviewImage] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [instruction, setInstruction] = useState({});
  const [isNotFirstRender, setIsNotFirstRender] = useState(false);
  const [isModalOpenVideo, setIsModalOpenVideo] = useState(false);
  const [isErrorVideoLink, setIsErrorVideoLink] = useState(false);
  const [defaultTimeExercise, setDefaultTimeExercise] = useState(dayjs().startOf('day'));
  const [isModalUrl, setIsModalUrl] = useState(false);
  const [isModalPreviewUrl, setIsModalPreviewUrl] = useState(false);
  const [isModalImageUrl, setIsModalImageUrl] = useState(false);
  const [isErrorPreviewLink, setIsErrorPreviewLink] = useState(false);
  const [isErrorImageLink, setIsErrorImageLink] = useState(false);
  const [isEditeImage, setIsEditeImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState('');
  const [cropSizeImage, setCropSizeImage] = useState({ width: 287, height: 159 });
  const [isPrevie, setIsPrevie] = useState(false);
  const [isUploadImg, setIsUploadImg] = useState(false);
  const { coachId } = useParams();
  const { planId } = useParams();
  const quillInstructionRef = useRef(null);
  const quillMistakesRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let token = localStorage.getItem('token-admin');

  useEffect(() => {
    if (planId?.length && !exerciseObj?._id?.length) {
      handleGetExercise();
    } else {
      handleSetInput();
    }
    setIsNotFirstRender(true);

    return () => {
      dispatch(setExerciseObj({}));
      handleClearInput();
    };
  }, []);

  useEffect(() => {
    if (isNotFirstRender) {
      if (videoLink?.length) {
        if (!handleValidURL(videoLink)) {
          setIsErrorVideoLink(true);
        } else {
          setIsErrorVideoLink(false);
        }
      } else {
        setIsErrorVideoLink(false);
      }

      if (previewLink?.length) {
        if (!handleValidURL(previewLink)) {
          setIsErrorPreviewLink(true);
        } else {
          setIsErrorPreviewLink(false);
        }
      } else {
        setIsErrorPreviewLink(false);
      }

      if (imageLink?.length) {
        if (!handleValidURL(imageLink)) {
          setIsErrorImageLink(true);
        } else {
          setIsErrorImageLink(false);
        }
      } else {
        setIsErrorImageLink(false);
      }

      dispatch(
        setExerciseObj({
          ...exerciseObj,
          name: exerciseName,
          number_sets: Number(numberSets),
          number_repetitions: Number(numberRepetitions),
          time: isExercise ? Number(timeExercise) : Number(timeRest),
          instruction_title: numberEquipment,
          instruction_text: instruction,
          instruction_mistakes: mistakes,
          coach: coach.first_name + ' ' + coach.last_name,
          coach_id: coach._id,
          is_exercise: isExercise,
          video: videoLink?.length && handleValidURL(videoLink) ? videoLink : video,
          image: imageLink?.length ? imageLink : image,
          preview: previewLink?.length ? previewLink : previewImage,

          newVideo: newVideo,
          newImage: newImage,
          newPreviewImage: newPreviewImage,
        }),
      );
    }
  }, [timeRest, imageLink, previewLink, videoLink, exerciseName, numberSets, timeExercise, numberRepetitions, numberEquipment, mistakes, instruction, newVideo, video, newImage, image, newPreviewImage, previewImage]);

  const handleSetInput = (data = null) => {
    if (data) {
      if (planId?.length && data?._id?.length && !data?.name?.length) {
        setIsExercise(false);
      }
    } else {
      if (planId?.length && exerciseObj?._id?.length && !exerciseObj?.name?.length) {
        setIsExercise(false);
      }
    }

    setExerciseName(data?.name?.length ? data?.name : exerciseObj?.name || '');
    setNumberSets(data?.number_sets ? data?.number_sets : exerciseObj?.number_sets ? exerciseObj?.number_sets : 0);
    if (data) {
      if (data.is_exercise) {
        setTimeExercise(data?.time ? data?.time : 0);
      } else {
        setTimeRest(data?.time ? data?.time : 0);
        setDefaultTimeExercise(convertTimeToHoursMinutes(data?.time ? data?.time : 0));
      }
    } else {
      if (exerciseObj.is_exercise) {
        setTimeExercise(exerciseObj?.time ? exerciseObj?.time : 0);
      } else {
        setTimeRest(exerciseObj?.time ? exerciseObj?.time : 0);
        setDefaultTimeExercise(convertTimeToHoursMinutes(exerciseObj?.time ? exerciseObj?.time : 0));
      }
    }
    setNumberRepetitions(data?.number_repetitions ? data?.number_repetitions : exerciseObj?.number_repetitions ? exerciseObj?.number_repetitions : 0);
    setNumberEquipment(data?.instruction_title?.length ? data?.instruction_title : exerciseObj?.instruction_title || '');
    setMistakes(data?.instruction_mistakes?.ops?.length && data?.instruction_mistakes?.ops[0].insert?.length > 2 ? data?.instruction_mistakes : exerciseObj?.instruction_mistakes || {});
    setInstruction(data?.instruction_text?.ops?.length && data?.instruction_text?.ops[0].insert?.length > 2 ? data?.instruction_text : exerciseObj?.instruction_text || {});
    setVideo(data?.video?.length ? data?.video : exerciseObj?.video || '');
    setImage(data?.image?.length ? data?.image : exerciseObj?.image || '');
    setPreviewImage(data?.preview?.length ? data?.preview : exerciseObj?.preview || '');
  };

  const handleClearInput = () => {
    setExerciseName('');
    setNumberSets(0);
    setTimeExercise(0);
    setTimeRest(0);
    setNumberRepetitions(0);
    setNumberEquipment('');
    setMistakes({});
    setInstruction({});
    setNewVideo({});
    setVideo('');
    setVideoLink('');
    setPreviewLink('');
    setImageLink('');
    setNewImage('');
    setImage('');
    setNewPreviewImage('');
    setPreviewImage('');
  };

  const handleUploadVideo = (file = null) => {
    if (file) {
      setNewVideo(file[0]);
      setVideo('');
      setVideoLink('');
    }
  };

  const handleUploadImg = async (file = null) => {
    if (file && file[0]?.name?.length) {
      setIsUploadImg(true);
      if (window.innerWidth < 640) {
        let obj = await handleUploadImgCompressor(file);
        if (obj !== null) {
          let str = await handleSaveMedia(token, obj);
          if (str?.length) {
            setImage(str);
          }
        }
      } else {
        let str = await handleSaveMedia(token, file[0]);
        if (str?.length) {
          setImage(str);
        }
      }
      setIsUploadImg(false);
    }
  };

  const handleUploadPreviewImg = async (file = null) => {
    if (file && file[0]?.name?.length) {
      let str = await handleSaveMedia(token, file[0]);
      if (str?.length) {
        setPreviewImage(str);
      }
    }
  };

  const handleGetExercise = () => {
    setIsPreloader(true);
    fetchGetData(`/exercises/${planId}?token=${token}`).then((res) => {
      if (res?.success && res?.data) {
        dispatch(setExerciseObj(res.data));
        handleSetInput(res.data);
      } else {
        navigate(`/auth/${coachId}/plans`);
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
      }
      setIsPreloader(false);
    });
  };

  const handleChangeTime = (e) => {
    let hours = e.$H;
    let minutes = e.$m;
    if (hours !== 0 || minutes !== 0) {
      setTimeRest(hours * 60 + minutes);
    }
  };

  const convertTimeToHoursMinutes = (time) => {
    const hours = Math.floor(time / 60);
    const minutes = time % 60;
    const formattedTime = dayjs().startOf('day').add(hours, 'hours').add(minutes, 'minutes');
    return formattedTime;
  };

  const handleModalUrl = () => {
    setIsModalUrl(true);
  };

  const handleModalImageUrl = () => {
    setIsModalImageUrl(true);
  };

  const handleModalPreviewUrl = () => {
    setIsModalPreviewUrl(true);
  };

  const handleIsModalUrl = (boolean) => {
    if (boolean && videoLink?.length && handleValidURL(videoLink)) {
      setVideo('');
      setNewVideo({});
      setIsModalUrl(false);
    } else {
      setVideoLink('');
      setIsModalUrl(false);
    }
  };

  const handleIsModalPreviewUrl = async (boolean) => {
    if (boolean && previewLink?.length && handleValidURL(previewLink)) {
      setIsPreloader(true);
      let res = await handleCopyImage(previewLink);
      setIsPreloader(false);
      if (res?.length) {
        setPreviewImage(res);
        setPreviewLink('');
        setIsModalPreviewUrl(false);
      } else {
        setPreviewImage('');
      }
      setNewPreviewImage('');
    } else {
      setPreviewLink('');
      setIsModalPreviewUrl(false);
    }
  };

  const handleIsModalImageUrl = async (boolean) => {
    if (boolean && imageLink?.length && handleValidURL(imageLink)) {
      setIsPreloader(true);
      let res = await handleCopyImage(imageLink);
      setIsPreloader(false);
      if (res?.length) {
        setImage(res);
        setImageLink('');
        setIsModalImageUrl(false);
      } else {
        setImage('');
      }
      setNewImage('');
    } else {
      setImageLink('');
      setIsModalImageUrl(false);
    }
  };

  const handleCopyImage = async (imageLink) => {
    let data = {
      url: imageLink,
    };
    return await fetchRequest('POST', `/files/copy-image?token=${token}`, data).then((res) => {
      if (res?.success) {
        return res.data;
      } else {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['errorUseLink'][language] }));
        return null;
      }
    });
  };

  const handleOpenEditeImage = (iaPrevie = false) => {
    if (iaPrevie) {
      setImageSrc(previewImage?.length ? previewImage : previewLink?.length ? previewLink : '');
      setIsPrevie(true);
      setCropSizeImage(windowInnerWidth > 400 ? { width: 460, height: 360 } : { width: 300, height: 240 });
    } else {
      setImageSrc(image?.length ? image : imageLink?.length ? imageLink : '');
      setIsPrevie(false);
      setCropSizeImage({ width: 90, height: 90 });
    }
    setIsEditeImage(true);
  };

  const handleIsEditeImage = (boolean) => {
    if (boolean) {
      showCroppedImage();
    } else {
      setIsEditeImage(false);
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    let imgSrc = '';
    if (isPrevie) {
      imgSrc = previewImage?.length ? previewImage : previewLink?.length ? previewLink : '';
    } else {
      imgSrc = image?.length ? image : imageLink?.length ? imageLink : '';
    }
    try {
      const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels, rotation);
      let str = await handleSaveMedia(token, croppedImage);
      if (str?.length) {
        if (isPrevie) {
          setNewPreviewImage(str);
        } else {
          setNewImage(str);
        }
      }
      setIsEditeImage(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeletePreview = () => {
    setNewPreviewImage('');
    setPreviewLink('');
    setPreviewImage('');
  };

  const handleDeleteImage = () => {
    setNewImage('');
    setImageLink('');
    setImage('');
  };

  const handleGetPreviewImageProps = () => {
    if (newPreviewImage?.length) {
      return { image: newPreviewImage };
    }
    if (previewLink?.length && !isModalPreviewUrl && !previewImage?.length && !newPreviewImage?.length) {
      return { image: previewLink };
    }
    if (previewImage?.length && (!previewLink?.length || (previewLink?.length && isModalPreviewUrl)) && !newPreviewImage?.length) {
      return { image: previewImage };
    }
    return null;
  };

  const handleGetEquipmentImageProps = () => {
    if (newImage?.length) {
      return { image: newImage };
    }
    if (imageLink?.length && !isModalImageUrl && !image?.length && !newImage?.length) {
      return { image: imageLink };
    }
    if (image?.length && (!imageLink?.length || (imageLink?.length && isModalImageUrl)) && !newImage?.length) {
      return { image: image };
    }
    return null;
  };

  const handleSetInstruction = (content, delta, source, editor) => {
    if (source === 'user') {
      const deltaFormat = editor.getContents();
      setInstruction(deltaFormat);
    }
  };

  const handleSetMistakes = (content, delta, source, editor) => {
    if (source === 'user') {
      const deltaFormat = editor.getContents();
      setMistakes(deltaFormat);
    }
  }

  return (
    <div className="create-exercise">
      {isEditeImage && (
        <ModalWindow
          title={''}
          handleClick={handleIsEditeImage}
          addStyles={{
            width: '100%',
            maxWidth: windowInnerWidth < 640 ? '100%' : '90%',
            height: '100%',
            maxHeight: windowInnerWidth < 640 ? '100%' : '90%',
            borderRadius: windowInnerWidth < 640 ? '0px' : '27px',
            padding: windowInnerWidth < 640 ? '16px 10px' : '16px 24px',
          }}
        >
          <div className="admin-training__modal-edite-image">
            {!!imageSrc?.length && (
              <div className="create-exercise__create-img-cropper-wrap">
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  zoom={zoom}
                  rotation={rotation}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                  onRotationChange={setRotation}
                  cropSize={cropSizeImage}
                  minZoom={0.1}
                  zoomSpeed={0.05}
                  objectFit={'cover'}
                />
              </div>
            )}
            <div className="admin-training__modal-edite-image-slider">
              <div className="admin-training__modal-edite-image-slider-wrap">
                <Typography variant="overline">{translations['zoom'][language]}</Typography>
                <Slider
                  value={zoom}
                  min={0.1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(zoom)}
                  className="admin-training__modal-edite-slider"
                />
              </div>
              <div className="admin-training__modal-edite-image-slider-wrap">
                <Typography variant="overline">{translations['rotation'][language]}</Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  onChange={(e, rotation) => setRotation(rotation)}
                  className="admin-training__modal-edite-slider"
                />
              </div>
            </div>
          </div>
        </ModalWindow>
      )}
      {isModalUrl && (
        <ModalWindow
          title={translations['linkToVideo'][language]}
          handleClick={handleIsModalUrl}
        >
          <TextInput
            setValue={setVideoLink}
            value={videoLink}
            label={translations['mainVideoLink'][language]}
            newStyle={{ maxWidth: '100%' }}
            helperText={errorExerciseObj.isErrorVideo ? translations['fieldRequired'][language] : isErrorVideoLink ? translations['errorVideoLink'][language] : null}
          />
        </ModalWindow>
      )}
      {isModalPreviewUrl && (
        <ModalWindow
          title={translations['linkToImage'][language]}
          handleClick={handleIsModalPreviewUrl}
          isRightBtnPreloader={isPreloader}
        >
          <TextInput
            setValue={setPreviewLink}
            value={previewLink}
            label={translations['mainPreview'][language]}
            newStyle={{ maxWidth: '100%' }}
            helperText={isErrorPreviewLink ? translations['linkNotCorrect'][language] : null}
          />
        </ModalWindow>
      )}
      {isModalImageUrl && (
        <ModalWindow
          title={translations['linkToImage'][language]}
          handleClick={handleIsModalImageUrl}
          isRightBtnPreloader={isPreloader}
        >
          <TextInput
            setValue={setImageLink}
            value={imageLink}
            label={translations['mainPreview'][language]}
            newStyle={{ maxWidth: '100%' }}
            helperText={isErrorImageLink ? translations['linkNotCorrect'][language] : null}
          />
        </ModalWindow>
      )}
      {isModalOpenVideo && (
        <ModalWindow
          title={''}
          handleClick={() => setIsModalOpenVideo(false)}
          isBtnClose={true}
          notBtn={true}
        >
          <ReactPlayer
            className="create-exercise__modal-video"
            url={newVideo?.name?.length ? URL.createObjectURL(newVideo) : video?.length ? video : videoLink}
            controls={true}
            config={{
              youtube: {
                playerVars: { modestbranding: 1, rel: 0, showinfo: 0 },
                embedOptions: { host: 'https://www.youtube.com', embedPath: '/embed/' },
                preload: false,
                xhrContext: { headers: { 'Access-Control-Allow-Origin': '*' } },
              },
            }}
            preload="metadata"
          />
        </ModalWindow>
      )}
      {!isExercise ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopTimePicker
            label={translations['hoursMinutes'][language]}
            defaultValue={defaultTimeExercise}
            value={timeRest !== 0 ? convertTimeToHoursMinutes(timeRest) : null}
            onChange={handleChangeTime}
            ampm={false} 
            views={['hours', 'minutes']}
            minutesStep={1}
            timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
            renderInput={(params) => <TextField {...params} />}
            helperText={errorExerciseObj.isErrorTime ? translations['fieldRequired'][language] : null}
          />
        </LocalizationProvider>
      ) : (
        <>
          {createTab === 1 ? (
            <>
              <div className="create-exercise__input-wrap">
                <TextInput
                  setValue={setExerciseName}
                  value={exerciseName}
                  label={translations['exerciseName'][language]}
                  newStyle={{ maxWidth: '100%' }}
                  helperText={errorExerciseObj.isErrorName ? translations['fieldRequired'][language] : null}
                />
                <NumberInput
                  setValue={setTimeExercise}
                  value={timeExercise}
                  label={translations['timeForExercise'][language]}
                  newStyle={{ maxWidth: '100%' }}
                  helperText={errorExerciseObj.isErrorTime ? translations['fieldRequired'][language] : null}
                />
                <NumberInput
                  setValue={setNumberSets}
                  value={numberSets}
                  label={translations['numberOfSets'][language]}
                  newStyle={{ maxWidth: '100%' }}
                  helperText={errorExerciseObj.isErrorNumber_sets ? translations['fieldRequired'][language] : null}
                />
                <NumberInput
                  setValue={setNumberRepetitions}
                  value={numberRepetitions}
                  label={translations['numberOfRepetitions'][language]}
                  newStyle={{ maxWidth: '100%' }}
                  helperText={errorExerciseObj.isErrorNumber_repetitions ? translations['fieldRequired'][language] : null}
                />
              </div>
              {((!video?.length && !newVideo?.name?.length && !videoLink?.length) || isModalUrl) && (
                <UploadImage
                  title={translations['selectDownloadVideo'][language]}
                  handleModalImageUrl={handleModalUrl}
                  handleUploadImg={handleUploadVideo}
                  isVideo={true}
                  isUploadImg={isUploadImg}
                />
              )}
              {!video?.length && !!newVideo?.name?.length && (!videoLink?.length || (!!videoLink?.length && isModalUrl)) && (
                <div className="create-exercise__create-img-result-wrap">
                  <div
                    className="create-exercise__create-video-result-wrap"
                    onClick={() => setIsModalOpenVideo(true)}
                  >
                    <img
                      className="create-exercise__create-video-result"
                      src={playCircleBlack}
                      alt="img"
                    />
                    <div className="create-exercise__create-img-result-name-wrap create-exercise__create-img-result-video">
                      <div className="create-exercise__create-img-result-name">
                        <span>{translations['mainVideo'][language]}</span> -<span> {handleBytesToMegabytes(newVideo.size)} mb</span>
                      </div>
                      <div className="create-exercise__create-img-result-watch">{translations['watchVideo'][language]}</div>
                    </div>
                  </div>
                  <img
                    className="create-exercise__create-img-result-delete"
                    onClick={() => setNewVideo({})}
                    src={close2}
                    alt="img"
                  />
                </div>
              )}
              {!!videoLink?.length && handleValidURL(videoLink) && !video?.length && !newVideo?.name?.length && !isModalUrl && (
                <div className="create-exercise__create-img-result-wrap">
                  <div
                    className="create-exercise__create-video-result-wrap"
                    onClick={() => setIsModalOpenVideo(true)}
                  >
                    <img
                      className="create-exercise__create-video-result"
                      src={playCircleBlack}
                      alt="img"
                    />
                    <div className="create-exercise__create-img-result-name-wrap create-exercise__create-img-result-video">
                      <div className="create-exercise__create-img-result-name">{translations['mainVideo'][language]}</div>
                      <div className="create-exercise__create-img-result-watch">{translations['watchVideo'][language]}</div>
                    </div>
                  </div>
                  <img
                    className="create-exercise__create-img-result-delete"
                    onClick={() => setVideoLink('')}
                    src={close2}
                    alt="img"
                  />
                </div>
              )}
              {!!video?.length && !newVideo?.name?.length && (!videoLink?.length || (!!videoLink?.length && isModalUrl)) && (
                <div className="create-exercise__create-img-result-wrap">
                  <div
                    className="create-exercise__create-video-result-wrap"
                    onClick={() => setIsModalOpenVideo(true)}
                  >
                    <img
                      className="create-exercise__create-video-result"
                      src={playCircleBlack}
                      alt="img"
                    />
                    <div className="create-exercise__create-img-result-name-wrap create-exercise__create-img-result-video">
                      <div className="create-exercise__create-img-result-name">{translations['mainVideo'][language]}</div>
                      <div className="create-exercise__create-img-result-watch">{translations['watchVideo'][language]}</div>
                    </div>
                  </div>
                  <img
                    className="create-exercise__create-img-result-delete"
                    onClick={() => setVideo('')}
                    src={close2}
                    alt="img"
                  />
                </div>
              )}
              {((!previewLink?.length && !previewImage?.length && !newPreviewImage?.length) || isModalPreviewUrl) && (
                <UploadImage
                  title={translations['downloadImage1'][language]}
                  handleModalImageUrl={handleModalPreviewUrl}
                  handleUploadImg={handleUploadPreviewImg}
                />
              )}
              {handleGetPreviewImageProps() && (
                <ResultImage
                  image={handleGetPreviewImageProps().image}
                  handleDelete={handleDeletePreview}
                  handleOpenEdite={() => handleOpenEditeImage(true)}
                  text={translations['previewVideo'][language]}
                  classEnd={'preview'}
                />
              )}
            </>
          ) : (
            <>
              <div className="create-exercise__input-wrap">
                <TextInput
                  setValue={setNumberEquipment}
                  value={numberEquipment}
                  label={translations['equipmentName'][language]}
                  newStyle={{ maxWidth: '100%' }}
                />
              </div>
              {((!imageLink?.length && !image?.length && !newImage?.length) || isModalImageUrl) && (
                <UploadImage
                  title={translations['downloadImage2'][language]}
                  handleModalImageUrl={handleModalImageUrl}
                  handleUploadImg={handleUploadImg}
                />
              )}
              {handleGetEquipmentImageProps() && (
                <ResultImage
                  image={handleGetEquipmentImageProps().image}
                  handleDelete={handleDeleteImage}
                  handleOpenEdite={() => handleOpenEditeImage(false)}
                  text={translations['equipmentImage'][language]}
                  classEnd={'equipment'}
                />
              )}
              <div className="create-exercise__instruction-wrap">
                <div className="create-exercise__instruction-title">{translations['stepByStep'][language]}</div>
                <ReactQuill
                  modules={{
                    toolbar: toolbarOptions,
                    clipboard: {
                      matchVisual: false,
                    },
                  }}
                  theme="snow"
                  value={instruction}
                  onChange={handleSetInstruction}
                  // preserveWhitespace={true}
                  // style={{ whiteSpace: 'pre-wrap' }}
                  className="create-exercise__instruction"
                  ref={quillInstructionRef}
                />
              </div>
              <div className="create-exercise__instruction-wrap">
                <div className="create-exercise__instruction-title">{translations['typicalMistakes'][language]}</div>
                <ReactQuill
                  modules={{
                    toolbar: toolbarOptions,
                    clipboard: {
                      matchVisual: false,
                    },
                  }}
                  theme="snow"
                  value={mistakes}
                  onChange={handleSetMistakes}
                  // preserveWhitespace={true}
                  // style={{ whiteSpace: 'pre-wrap' }}
                  className="create-exercise__instruction"
                  ref={quillMistakesRef}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default memo(CreateExercise);
