import './ShowMessage.css';
import { useState, useEffect } from 'react';
import { Alert, Snackbar, Stack } from '@mui/material';
import { useSelector } from 'react-redux';

function ShowMessage() {
  const showMessageObj = useSelector((state) => state.homeSlice.showMessageObj);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (showMessageObj?.open) {
      setOpen(true);
    }
  }, [showMessageObj]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Stack
      spacing={2}
      sx={{ width: '100%' }}
    >
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {showMessageObj?.status == 'success' ? (
          <Alert
            className="show-message__success"
            onClose={handleClose}
            severity="success"
            sx={{ width: '100%' }}
          >
            {showMessageObj.message}
          </Alert>
        ) : showMessageObj?.status == 'error' ? (
          <Alert
            className="show-message__error"
            onClose={handleClose}
            severity="error"
            sx={{ width: '100%' }}
          >
            {showMessageObj.message}
          </Alert>
        ) : (
          <></>
        )}
      </Snackbar>
    </Stack>
  );
}

export default ShowMessage;
