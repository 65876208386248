import './ModalNotifications.css';
import { useState, memo, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { fetchGetData, handleCheckNewMessages, handleFormatDateWeek, handleGetUserName, handleHoursAndMinutes } from '../../helpers/Utils';
import { translations } from '../../localization';
import { useDispatch } from 'react-redux';
import { setIsUnreadMessages, setModalNewNotifications, setModalSeenNotifications } from '../../store/userSlice';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { parseISO } from 'date-fns';
import defaultAvatar from '../../assets/defaultAvatar.png';
import cardTimeImg from '../../assets/cardTimeImg.svg';
import { setIsUnreadMessagesUser, setUserModalNewNotifications, setUserModalSeenNotifications } from '../../store/homeSlice';
import { NEWCARD } from '../../helpers/Config';

function ModalNotifications({ handleClose, is_coach = false }) {
  const modalNewNotifications = useSelector((state) => state.userSlice.modalNewNotifications);
  const modalSeenNotifications = useSelector((state) => state.userSlice.modalSeenNotifications);
  const windowInnerWidth = useSelector((state) => state.userSlice.windowInnerWidth);
  const coach = useSelector((state) => state.userSlice.coach);
  const userModalSeenNotifications = useSelector((state) => state.homeSlice.userModalSeenNotifications);
  const userModalNewNotifications = useSelector((state) => state.homeSlice.userModalNewNotifications);
  const languageAdmin = useSelector((state) => state.userSlice.language);
  const languageUser = useSelector((state) => state.homeSlice.language);
  const [isRead, setIsRead] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [page, setPage] = useState(0);
  const [isHasMore, setIsHasMore] = useState(true);
  const [isNotFirstRender, setIsNotFirstRender] = useState(false);
  const [groupedMessages, setGroupedMessages] = useState([]);
  const [language, setLanguage] = useState('en');
  const [token, setToken] = useState('');
  const scrollParentRef = useRef(null);
  const dispatch = useDispatch();
  const tokenAdmin = localStorage.getItem('token-admin');
  const tokenUser = localStorage.getItem('token-user');

  useEffect(() => {
    setIsNotFirstRender(true);
    if (is_coach) {
      setLanguage(languageAdmin);
      setToken(tokenAdmin);
      handleGetNotifications(0, null, tokenAdmin);
    } else {
      setLanguage(languageUser);
      setToken(tokenUser);
      handleGetNotifications(0, null, tokenUser);
    }
  }, []);

  useEffect(() => {
    if (is_coach) {
      if (isRead) {
        setGroupedMessages(groupMessagesByDate(modalSeenNotifications));
      } else {
        setGroupedMessages(groupMessagesByDate(modalNewNotifications));
      }
    } else {
      if (isRead) {
        setGroupedMessages(groupMessagesByDate(userModalSeenNotifications));
      } else {
        setGroupedMessages(groupMessagesByDate(userModalNewNotifications));
      }
    }
  }, [modalNewNotifications, modalSeenNotifications, userModalSeenNotifications, userModalNewNotifications, isRead]);

  const handleActiveTab = (boolean) => {
    setIsRead(boolean);
    setPage(0);
    setIsHasMore(false);
    handleGetNotifications(0, boolean);
  };

  const handleLoadMore = () => {
    setIsHasMore(false);
    if (isHasMore && isNotFirstRender) {
      handleGetNotifications(page + 1);
      setPage(page + 1);
    }
  };

  const handleGetNotifications = (newPage = null, boolean = null, newToken = null) => {
    setIsHasMore(false);
    fetchGetData(`/notifications/all?is_updated=false&page=${newPage !== null ? newPage : page}&is_read=${boolean !== null ? boolean : isRead}&search=&token=${newToken ? newToken : token}`).then((res) => {
      if (res?.success && res?.data) {
        let newData = res.data?.items || [];
        let updatedData = [];
        setIsHasMore(res.data.is_has_more ? true : false);
        setArchivedCount(res.data?.archived_count ? res.data?.archived_count : 0);
        setTotalCount(res.data?.count ? res.data?.count : 0);
        if (is_coach) {
          if ((boolean !== null && boolean) || (boolean === null && isRead)) {
            updatedData = newPage ? [...modalSeenNotifications, ...newData] : newData;
            dispatch(setModalSeenNotifications(updatedData));
          } else {
            updatedData = newPage ? [...modalNewNotifications, ...newData] : newData;
            dispatch(setModalNewNotifications(updatedData));
          }
        } else {
          if ((boolean !== null && boolean) || (boolean === null && isRead)) {
            updatedData = newPage ? [...userModalSeenNotifications, ...newData] : newData;
            dispatch(setUserModalSeenNotifications(updatedData));
          } else {
            updatedData = newPage ? [...userModalNewNotifications, ...newData] : newData;
            dispatch(setUserModalNewNotifications(updatedData));
          }
        }
        if (!res.data.is_has_more) {
          if (is_coach) {
            handleCheckNewMessages(newToken?.length ? newToken : token, (res) => dispatch(setIsUnreadMessages(res)));
          } else {
            handleCheckNewMessages(newToken?.length ? newToken : token, (res) => dispatch(setIsUnreadMessagesUser(res)));
          }
        }
      }
    });
  };

  const groupMessagesByDate = (messages) => {
    if (messages?.length) {
      const groupedMessages = {};
      messages.forEach((message) => {
        const date = parseISO(message.create_at);
        let dateKey = handleFormatDateWeek(date, language);
        if (!groupedMessages[dateKey]) {
          groupedMessages[dateKey] = [];
        }
        groupedMessages[dateKey].push(message);
      });

      return groupedMessages;
    }
  };

  return (
    <div className="modal-notifications">
      <h3 className="modal-notifications__title">{translations['notifications'][language]}</h3>
      <div className="modal-notifications__btn-wrap">
        <button
          className={`modal-notifications__btn ${!isRead ? 'modal-notifications__btn--active' : ''}`}
          onClick={() => handleActiveTab(false)}
        >
          {translations['all'][language]} {totalCount}
        </button>
        <button
          className={`modal-notifications__btn ${isRead ? 'modal-notifications__btn--active' : ''}`}
          onClick={() => handleActiveTab(true)}
        >
          {translations['seen'][language]} {archivedCount}
        </button>
      </div>
      <div
        className="modal-notifications__content"
        ref={scrollParentRef}
      >
        {groupedMessages ? (
          <InfiniteScroll
            pageStart={0}
            useWindow={false}
            getScrollParent={() => scrollParentRef.current}
            loadMore={handleLoadMore}
            hasMore={isHasMore}
            className="admin-notifications__content-item-wrap"
          >
            {Object.keys(groupedMessages).map((dateKey) => (
              <div
                className="modal-notifications__content-day-wrap"
                key={dateKey}
              >
                <div className="modal-notifications__content-day">{dateKey}</div>
                <div className="modal-notifications__content-items">
                  {groupedMessages[dateKey].map((message) => (
                    <div
                      className="modal-notifications__content-item"
                      key={message._id}
                    >
                      <div className="modal-notifications__content-item-img-wrap">
                        {message.type === NEWCARD ? (
                          <img
                            className="modal-notifications__content-item-img"
                            src={cardTimeImg}
                            alt="img"
                          />
                        ) : (
                          <img
                            className="modal-notifications__content-item-img"
                            src={message.image?.length ? message.image : defaultAvatar}
                            alt="img"
                          />
                        )}
                        {!message.image?.length && !!message.user_name?.length && message.type !== NEWCARD && <div className="modal-notifications__content-item-img-text">{is_coach ? handleGetUserName(message.user_name) : !is_coach ? handleGetUserName(message.coach_name) : ''}</div>}
                      </div>
                      <div className="modal-notifications__content-item-text-wrap">
                        <div className="modal-notifications__content-item-text">
                          <span className="modal-notifications__content-item-text-name">{is_coach ? message.user_name : !is_coach ? message.coach_name : ''}</span>
                          <span className="modal-notifications__content-item-text">
                            {!!message.type?.length ? translations[message.type][language] : ''} {!!message?.text[language]?.length ? message.text[language] : ''}
                          </span>
                        </div>
                        <div className="modal-notifications__content-item-date">{handleHoursAndMinutes(message.create_at)}</div>
                        {message.type === NEWCARD && (
                          <NavLink
                            className="mainBtnDark modal-notifications__content-item-btn-add"
                            to={windowInnerWidth < 640 ? `/auth/${coach._id}/profile?is_info=false` : `/auth/${coach._id}/bills`}
                          >
                            {translations['goToBilling'][language]}
                          </NavLink>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <div className="modal-notifications__empty-wrap">{translations['messagesListEmpty'][language]}</div>
        )}
      </div>
      <div className="modal-notifications__btn-all-wrap">
        <NavLink
          className="modal-notifications__btn-all"
          to={`notifications?is_read=${isRead}`}
          onClick={handleClose}
        >
          {translations['showAll'][language]}
        </NavLink>
      </div>
    </div>
  );
}

export default memo(ModalNotifications);
