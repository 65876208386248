import './ModalWindow.css';
import { memo } from 'react';
import close2 from '../../assets/close2.svg';
import { useSelector } from 'react-redux';
import { translations } from '../../localization';
import { useParams } from 'react-router-dom';

function ModalWindow({ title = '', text = '', handleClick, leftBtn = '', rightBtn = '', isRightBtnPreloader = false, notBtn = false, isBtnClose = false, addStyles = {}, children }) {
  const { userId } = useParams();
  const language = useSelector((state) => (userId?.length ? state.homeSlice.language : state.userSlice.language));

  const handleClickBtn = (boolean) => {
    handleClick(boolean);
  };

  const handleClose = () => {
    handleClick(false);
  };

  return (
    <div className="modal-window">
      <div
        className="modal-window-wrap"
        onClick={handleClose}
      ></div>
      <div
        className="modal-window--wrap"
        style={addStyles}
        onClick={(e) => e.stopPropagation()}
      >
        {isBtnClose && (
          <button className="modal-window__close-btn">
            <img
              className="modal-window__close-img"
              src={close2}
              alt="close"
              onClick={handleClose}
            />
          </button>
        )}
        {!!title.length && <h3 className="modal-window__title">{title}</h3>}
        {!!text?.length && <div className="modal-window__text">{text}</div>}
        {children}
        {!notBtn && (
          <div className="modal-window__btn-wrap">
            <button
              className="mainBtnWhite modal-window__btn"
              onClick={() => handleClickBtn(false)}
            >
              {!!leftBtn?.length ? leftBtn : translations['cancel'][language]}
            </button>
            <button
              className="mainBtnDark modal-window__btn"
              onClick={() => handleClickBtn(true)}
              disabled={isRightBtnPreloader}
            >
              {isRightBtnPreloader ? <span className="modal-window__btn-loader"></span> : <>{!!rightBtn?.length ? rightBtn : translations['confirm'][language]}</>}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(ModalWindow);
