import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  date: [],
  showMessageObj: {
    open: false,
    message: '',
    status: '',
  },
  language: 'en',
  userPayTraining: {},
  userTraining: {},
  userExercise: {},
  userTrainings: [],
  userModalNewNotifications: [],
  userModalSeenNotifications: [],
  newNotifications: [],
  seenNotifications: [],
  isModalFinished: false,
  isUnreadMessagesUser: false,
  is_app: false,
  user: {},
  windowInnerWidth: window.innerWidth,
};

export const homeSlice = createSlice({
  name: 'homeSlice',
  initialState,
  reducers: {
    getDate: (state, action) => {
      state.date = action.payload;
    },
    setShowMessageObj: (state, action) => {
      state.showMessageObj = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserTrainings: (state, action) => {
      state.userTrainings = action.payload;
    },
    setUserTraining: (state, action) => {
      state.userTraining = action.payload;
    },
    setUserExercise: (state, action) => {
      state.userExercise = action.payload;
    },
    setUserPayTraining: (state, action) => {
      state.userPayTraining = action.payload;
    },
    setIsModalFinished: (state, action) => {
      state.isModalFinished = action.payload;
    },
    setIsUnreadMessagesUser: (state, action) => {
      state.isUnreadMessagesUser = action.payload;
    },
    setUserModalNewNotifications: (state, action) => {
      state.userModalNewNotifications = action.payload;
    },
    setUserModalSeenNotifications: (state, action) => {
      state.userModalSeenNotifications = action.payload;
    },
    setSeenNotifications: (state, action) => {
      state.seenNotifications = action.payload;
    },
    setNewNotifications: (state, action) => {
      state.newNotifications = action.payload;
    },
    setWindowInnerWidth: (state, action) => {
      state.windowInnerWidth = action.payload;
    },
    setIs_app: (state, action) => {
      state.is_app = action.payload;
    },
  },
});

export const { setIs_app, setWindowInnerWidth, setNewNotifications, setSeenNotifications, setUserModalSeenNotifications, setUserModalNewNotifications, setIsUnreadMessagesUser, setIsModalFinished, setUserPayTraining, setUserExercise, setUserTraining, setUserTrainings, setUser, setLanguage, setShowMessageObj, getDate } = homeSlice.actions;

export default homeSlice.reducer;
