import './BillingView.css';
import { NavLink, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { pageMeta } from '../../pageMeta';
import PageMeta from '../../components/PageMeta/PageMeta';
import { translations } from '../../localization';
import { setShowMessageObj } from '../../store/homeSlice';
import PreloaderCustom from '../../components/PreloaderCustom/PreloaderCustom';
import { useDispatch } from 'react-redux';
import { fetchGetData, handleFormatDateWeek, handleGoStripe } from '../../helpers/Utils';
import ModalWindow from '../../components/ModalWindow/ModalWindow';
import downloadImg from '../../assets/downloadImg.svg';
import downloadImg2 from '../../assets/downloadImg2.svg';
import excelImg2 from '../../assets/excelImg2.svg';
import InfiniteScroll from 'react-infinite-scroller';
import { format } from 'date-fns';
import CurrencySymbol from '../../components/CurrencySymbol/CurrencySymbol';
import notTransactionsImg from '../../assets/notTransactionsImg.svg';
import LoadingHasMore from '../../components/LoadingHasMore/LoadingHasMore';

function BillingView() {
  const language = useSelector((state) => state.userSlice.language);
  const coach = useSelector((state) => state.userSlice.coach);
  const windowInnerWidth = useSelector((state) => state.userSlice.windowInnerWidth);
  const is_app = useSelector((state) => state.homeSlice.is_app);
  const [isModalDownload, setIsModalDownload] = useState(false);
  const [isPreloader, setIsPreloader] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [isHasMore, setIsHasMore] = useState(false);
  const [isLoadingHasMore, setIsLoadingHasMore] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const { coachId } = useParams();
  const scrollParentRef = useRef(null);
  let token = localStorage.getItem('token-admin');

  useEffect(() => {
    if (coach._id?.length) {
      handleGetTransactions();
    }
  }, [coach]);

  const handleIsDownload = (boolean) => {
    if (boolean) {
      handleDownloadTransactions();
    } else {
      setIsModalDownload(false);
    }
  };

  const handleDownloadTransactions = () => {
    setIsPreloader(true);
    fetchGetData(`/download-transactions?token=${token}`).then((res) => {
      if (res?.success && res?.data) {
        dispatch(setShowMessageObj({ open: true, status: 'success', message: translations['messageSent'][language] }));
      } else {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
      }
      setIsModalDownload(false);
      setIsPreloader(false);
    });
  };

  const handleGetTransactions = (newPage = null) => {
    if (coach?.stripe_id?.length) {
      if (newPage === null || newPage === 0) {
        setIsPreloader(true);
      } else {
        setIsLoadingHasMore(true);
      }
      fetchGetData(`/stripe/transactions/${coach.stripe_id}?page=${newPage !== null ? newPage : page}&token=${token}`).then((res) => {
        if (res?.success && res?.data) {
          setTransactions(newPage ? [...transactions, ...res.data.items] : res.data.items);
          setIsHasMore(res?.data?.is_has_more ? true : false);
          setTotalCount(res.data?.count || 0);
        } else {
          dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['anErrorOccurred'][language] }));
        }
        setIsPreloader(false);
        setIsLoadingHasMore(false);
      });
    }
  };

  const handleLoadMore = () => {
    setIsHasMore(false);
    if (isHasMore) {
      handleGetTransactions(page + 1);
      setPage(page + 1);
    }
  };

  const handleClickStripe = () => {
    handleGoStripe(coach, token, is_app, dispatch, language);
  };

  return (
    <div className="billing-view-wrap">
      <PageMeta {...pageMeta['BillingView']} />
      {isPreloader && <PreloaderCustom />}
      {isModalDownload && (
        <ModalWindow
          title={''}
          handleClick={handleIsDownload}
          isRightBtnPreloader={isPreloader}
        >
          <div className="admin-trainings__dialogform-delete-wrap">
            <img
              className="admin-trainings__dialogform-delete-img"
              src={downloadImg2}
              alt="img"
            />
            <div className="admin-trainings__dialogform-delete-text">
              <div>{translations['downloadExcelText'][language]}</div>
            </div>
          </div>
        </ModalWindow>
      )}
      <div className="billing-view">
        {windowInnerWidth < 640 ? (
          <>
            <div className="admin-profile__info-tab-wrap">
              <NavLink
                className="admin-profile__info-tab"
                to={`/auth/${coachId}/profile`}
              >
                {translations['info'][language]}
              </NavLink>
              <button className="admin-profile__info-tab admin-profile__info-tab--active">{translations['billing'][language]}</button>
            </div>
            {!!transactions?.length ? (
              <>
                <button
                  className="mainBtnWhite billing-view__mob-btn-stripe"
                  onClick={handleClickStripe}
                >
                  {translations['goToStripe'][language]}
                </button>
                {!!transactions?.length && (
                  <button
                    className="billing-view__btn-download"
                    onClick={() => setIsModalDownload(true)}
                  >
                    <img
                      className="billing-view__btn-download-img"
                      src={downloadImg}
                      alt="img"
                    />
                    <span>{translations['downloadExcel'][language]}</span>
                  </button>
                )}
                <div className="billing-view__transactions-wrap">
                  <InfiniteScroll
                    pageStart={0}
                    useWindow={false}
                    getScrollParent={() => scrollParentRef.current}
                    loadMore={handleLoadMore}
                    hasMore={isHasMore}
                    className="billing-view__transaction-item-cards billing-view__transaction-item-cards--gap"
                  >
                    {transactions.map((el, index) => (
                      <div
                        className="billing-view__transactions"
                        key={index}
                      >
                        <div className="billing-view__transactions-top">
                          <div className="billing-view__transactions-title">{handleFormatDateWeek(el.created, language)}</div>
                          <div className="billing-view__transactions-title">${el.amount / 100}</div>
                        </div>
                        <div className="billing-view__transactions-bottom">
                          <div className="billing-view__transactions-text">{format(new Date(el.created), 'MM-dd-yyyy')}</div>
                          <a
                            className="mainBtnDark billing-view__transactions-btn"
                            href={el.receipt_url}
                            target="_blank"
                          >
                            {translations['watch'][language]}
                          </a>
                        </div>
                      </div>
                    ))}
                    {
                      isLoadingHasMore && <LoadingHasMore />
                    }
                  </InfiniteScroll>
                </div>
              </>
            ) : (
              <div className="billing-view__empty-img-wrap">
                <img
                  className="billing-view__empty-img"
                  src={notTransactionsImg}
                  alt="img"
                />
                <div className="billing-view__no-transactions">{translations['noTransactions'][language]}</div>
                <div className="billing-view__add-btn-wrap">
                  <button
                    className="mainBtnWhite"
                    onClick={handleClickStripe}
                  >
                    {translations['goToStripe'][language]}
                  </button>
                  {!!transactions?.length && (
                    <button
                      className="mainBtnDark billing-view__download-btn"
                      onClick={() => setIsModalDownload(true)}
                    >
                      <img
                        className="billing-view__transaction-btn-download-img"
                        src={excelImg2}
                        alt="img"
                      />
                      <span>{translations['downloadExcel'][language]}</span>
                    </button>
                  )}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="admin-all-plans__main-wrap">
              <div className="admin-all-plans__btn--wrap">
                <div className="admin-all-plans__count-wrap">
                  <div className="admin-all-plans__count">{totalCount}</div>
                  <div className="admin-all-plans__count-text">{translations['transactions'][language]}</div>
                </div>
                <div className="admin-all-plans__price-wrap"></div>
              </div>
              <div className="billing-view__transaction-item-cards--wrap">
                {!!transactions?.length ? (
                  <div
                    className="billing-view__transaction-item-cards-wrap"
                    ref={scrollParentRef}
                  >
                    <InfiniteScroll
                      pageStart={0}
                      useWindow={false}
                      getScrollParent={() => scrollParentRef.current}
                      loadMore={handleLoadMore}
                      hasMore={isHasMore}
                      className="billing-view__transaction-item-cards"
                    >
                      <div className="billing-view__transaction-header-wrap">
                        <div className="billing-view__transaction-header-date">{translations['date'][language]}</div>
                        <div className="billing-view__transaction-header-period">{translations['period'][language]}</div>
                        <div className="billing-view__transaction-header-amount">{translations['amount'][language]}</div>
                        <div className="billing-view__transaction-header-number">{translations['receipt'][language]}</div>
                      </div>
                      {transactions.map((el, i) => (
                        <div
                          className="billing-view__transaction-item-card"
                          key={i}
                        >
                          <div className="billing-view__transaction-item-card-date-wrap">
                            <span className="billing-view__transaction-item-card-index">{i + 1}</span>
                            <span className="billing-view__transaction-item-card-date">{format(new Date(el.created), 'MM-dd-yyyy')}</span>
                          </div>
                          <div className="billing-view__transaction-item-card-period">{handleFormatDateWeek(el.created, language)}</div>
                          <div className="billing-view__transaction-item-card-amount">
                            {!!el.currency?.length && <CurrencySymbol currency={el.currency} />}
                            {el.amount / 100}
                          </div>
                          <a
                            className="mainBtnDark billing-view__transactions-btn"
                            href={el.receipt_url}
                            target="_blank"
                          >
                            {translations['watch'][language]}
                          </a>
                        </div>
                      ))}
                      {
                        isLoadingHasMore && <LoadingHasMore />
                      }
                    </InfiniteScroll>
                  </div>
                ) : (
                  <div className="billing-view__empty-img-wrap">
                    <img
                      className="billing-view__empty-img"
                      src={notTransactionsImg}
                      alt="img"
                    />
                    <div className="admin-all-plans__empty-wrap">{translations['noTransactions'][language]}</div>
                  </div>
                )}
              </div>
            </div>
            <div className="billing-view__add-btn-wrap">
              <button
                className="mainBtnWhite"
                onClick={handleClickStripe}
              >
                {translations['goToStripe'][language]}
              </button>
              {!!transactions?.length && (
                <button
                  className="mainBtnDark billing-view__download-btn"
                  onClick={() => setIsModalDownload(true)}
                >
                  <img
                    className="billing-view__transaction-btn-download-img"
                    src={excelImg2}
                    alt="img"
                  />
                  <span>{translations['downloadExcel'][language]}</span>
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default BillingView;
