import './LayoutHome.css';
import { useEffect, useRef, useState } from 'react';
import { NavLink, Outlet, useLocation, useSearchParams } from 'react-router-dom';
import logoWhite from '../assets/logoWhite.svg';
import logo from '../assets/logo.svg';
import tikTok from '../assets/tikTok.svg';
import facebook2 from '../assets/facebook2.svg';
import instagram2 from '../assets/instagram2.svg';
import { setShowMessageObj, setWindowInnerWidth } from '../store/homeSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchRequest, handleCheckApp, handleUpdateEmail, handleUpdatePassword } from '../helpers/Utils';
import { translations } from '../localization';
import PreloaderCustom from '../components/PreloaderCustom/PreloaderCustom';
import SelectLanguage from '../components/SelectLanguage/SelectLanguage';
import ModalWindow from '../components/ModalWindow/ModalWindow';
import ConfettiFinish from '../components/ConfettiFinish/ConfettiFinish';
import finish from '../assets/finish.svg';
import dotImg from '../assets/dotImg.svg';
import envelopeImage from '../assets/envelopeImage.svg';

function LayoutHome() {
  const [searchParams, setSearchParams] = useSearchParams();
  const newSearchParams = new URLSearchParams(searchParams);
  const stripe_activatedSearchParams = newSearchParams.get('stripe_activated');
  const tokenSearchParams = newSearchParams.get('token');
  const coach_idSearchParams = newSearchParams.get('coach_id');
  const is_paidSearchParams = newSearchParams.get('is_paid');
  const assigned_idSearchParams = newSearchParams.get('assigned_id');
  const is_passwordSearchParams = newSearchParams.get('is_password');
  const is_emailSearchParams = newSearchParams.get('is_email');
  const windowInnerWidth = useSelector((state) => state.homeSlice.windowInnerWidth);
  const language = useSelector((state) => state.homeSlice.language);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isPreloader, setIsPreloader] = useState(true);
  const [isModalFinished, setIsModalFinished] = useState(false);
  const [isStripeActivated, setIsStripeActivated] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const location = useLocation();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const contentRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (stripe_activatedSearchParams?.length && tokenSearchParams?.length && coach_idSearchParams?.length) {
      setIsModalFinished(true);
      setIsStripeActivated(true);
    }

    if (is_paidSearchParams?.length && tokenSearchParams?.length && assigned_idSearchParams?.length) {
      setIsModalFinished(true);
      setIsPaid(true);
      // handlePaid();
    }

    if (is_passwordSearchParams?.length && tokenSearchParams?.length) {
      setIsModalFinished(true);
      setIsPassword(true);
      handleUpdatePassword(tokenSearchParams, dispatch, language);
    }

    if (is_emailSearchParams?.length && tokenSearchParams?.length) {
      setIsModalFinished(true);
      setIsEmail(true);
      handleUpdateEmail(tokenSearchParams, dispatch, translations, language);
    }

    let localLanguage = localStorage.getItem('localLanguage');
    if (localLanguage?.length) {
      setIsPreloader(false);
    } else {
      setTimeout(() => {
        setIsPreloader(false);
      }, 1000);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleScrollBtn();
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = contentRef.current.scrollTop;
      const isVisible = prevScrollPos > currentScrollPos;
      setPrevScrollPos(currentScrollPos);
      const navbarElement = document.getElementById('layoutHomeHeaderId');
      const navbarElementWrap = document.getElementById('layoutHomeHeaderWrapId');
      if (isVisible && currentScrollPos > 1) {
        navbarElement.classList.add('layout-home__header--fixed');
        navbarElementWrap.classList.add('layout-home__header-wrap--fixed');
        setTimeout(() => {}, 500);
      } else {
        navbarElement.classList.remove('layout-home__header--fixed');
        navbarElementWrap.classList.remove('layout-home__header-wrap--fixed');
        setTimeout(() => {}, 500);
      }
    };

    const contentElement = contentRef.current;
    contentElement.addEventListener('scroll', handleScroll);

    return () => {
      contentElement.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  const handleScrollBtn = () => {
    const element = document.getElementById('layoutHomeId');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleResize = () => {
    dispatch(setWindowInnerWidth(window.innerWidth));
    if (window.innerWidth > 1150) {
      setIsOpenMenu(false);
    }
  };

  const handlePaid = () => {
    let data = {
      assigned_id: assigned_idSearchParams,
    };
    fetchRequest('POST', `/assigned/payload?token=${tokenSearchParams}`, data).then((res) => {
      if (!res?.success) {
        dispatch(setShowMessageObj({ open: true, status: 'error', message: translations['sorryWrong'][language] }));
      }
    });
  };

  const handleCloseModalFinished = () => {
    setIsModalFinished(false);
    setIsStripeActivated(false);
    setIsPassword(false);
    setIsPaid(false);
    setIsEmail(false);
    const newSearchParams = new URLSearchParams();
    setSearchParams(newSearchParams);
  };

  const handleClickCheckApp = () => {
    handleCheckApp(
      'tg://resolve?domain=telegram', // iOS URL-схема
      'org.telegram.messenger', // Android package name
      'intent://resolve?domain=telegram#Intent;package=org.telegram.messenger;scheme=tg;end;', // Android URL-схема
      'https://telegram.org', // Fallback URL
    );
  };

  return (
    <div
      ref={contentRef}
      className="layout-home--wrpa"
    >
      {isPreloader && <PreloaderCustom newStyle={{ height: '100vh' }} />}
      {isModalFinished && (
        <ModalWindow
          title={''}
          handleClick={handleCloseModalFinished}
          notBtn={true}
        >
          <img
            className="time-progress__modal-img"
            src={isPassword ? dotImg : isEmail ? envelopeImage : finish}
            alt="img"
          />
          <h3 className="time-progress__modal-title">{isPassword ? translations['passwordUpdated'][language] : isEmail ? translations['emailUpdated'][language] : translations['congratulation'][language]}</h3>
          <div className="time-progress__modal-text">
            {isStripeActivated && <span>{translations['registrationStripe'][language]}</span>}
            {isPaid && <span>{translations['paymentSuccessful'][language]}</span>}
          </div>
          <div className="time-progress__modal-btn-wrap">
            <button
              className="mainBtnDark mainBtn--whidth"
              onClick={handleCloseModalFinished}
            >
              {translations['close'][language]}
            </button>
          </div>
          <ConfettiFinish />
        </ModalWindow>
      )}
      <div
        id="layoutHomeId"
        className="layout-home"
      >
        {prevScrollPos > 100 && !isOpenMenu && (
          <button
            className="layout-home__btn-scroll"
            onClick={handleScrollBtn}
          >
            <span className="layout-home__btn-scroll-span"></span>
          </button>
        )}
        <div
          id="layoutHomeHeaderId"
          className="layout-home__header"
        >
          <div
            id="layoutHomeHeaderWrapId"
            className={`layout-home__header--wrap ${location.pathname === '/not-found' && 'layout-home__header--not-found'}`}
          >
            <NavLink to="/">
              <img
                className="layout-home__header-logo"
                src={logoWhite}
                alt="img"
              />
            </NavLink>
            {isOpenMenu && (
              <div className="layout-home__side-bar">
                <div className="layout-home__side-bar-link-wrap">
                  <div className="layout-home__side-bar-lang-wrap">
                    <SelectLanguage
                      isLayoutHome={true}
                      setIsPreloader={setIsPreloader}
                    />
                  </div>
                  <div className="layout-home__side-bar-link--wrap">
                    <NavLink
                      className="layout-home__side-bar-link"
                      onClick={() => setIsOpenMenu(false)}
                      to={`/?language=${language}`}
                    >
                      {translations['home'][language]}
                    </NavLink>
                    <NavLink
                      className="layout-home__side-bar-link"
                      onClick={() => setIsOpenMenu(false)}
                      to={`/coaches?language=${language}`}
                    >
                      {translations['coach'][language]}
                    </NavLink>
                    <NavLink
                      className="layout-home__side-bar-link"
                      onClick={() => setIsOpenMenu(false)}
                      to={`/products?language=${language}`}
                    >
                      {translations['product'][language]}
                    </NavLink>
                    <NavLink
                      className="layout-home__side-bar-link"
                      onClick={() => setIsOpenMenu(false)}
                      to={`/about?language=${language}`}
                    >
                      {translations['aboutUs'][language]}
                    </NavLink>
                  </div>
                  <div className="layout-home__side-bar-login-wrap">
                    {windowInnerWidth < 960 ? (
                      <>
                        {/* <button
                                                            className='layout-home__header-login' 
                                                            onClick={handleClickCheckApp}
                                                        >
                                                            {translations['registration'][language]}
                                                        </button>
                                                        <button
                                                            className='layout-home__header-login' 
                                                            onClick={handleClickCheckApp}
                                                        >
                                                            {translations['login'][language]}
                                                        </button> */}
                        <NavLink
                          className="layout-home__header-login"
                          to={`/register?language=${language}`}
                        >
                          {translations['registration'][language]}
                        </NavLink>
                        <NavLink
                          className="layout-home__header-login"
                          to={`/login?language=${language}`}
                        >
                          {translations['login'][language]}
                        </NavLink>
                      </>
                    ) : (
                      <>
                        <NavLink
                          className="layout-home__header-register"
                          to={`/register?language=${language}`}
                        >
                          {translations['registration'][language]}
                        </NavLink>
                        <NavLink
                          className="layout-home__header-login"
                          to={`/login?language=${language}`}
                        >
                          {translations['login'][language]}
                        </NavLink>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
            {windowInnerWidth < 1150 ? (
              <div
                className={`layout-home___mobile-menu ${isOpenMenu ? 'layout-home___mobile-menu--active' : ''}`}
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              >
                <div className="layout-home___mobile-menu-bar1"></div>
                <div className="layout-home___mobile-menu-bar2"></div>
                <div className="layout-home___mobile-menu-bar3"></div>
              </div>
            ) : (
              <>
                <div className="layout-home__header-btn-wrap">
                  <NavLink
                    className={`layout-home__header-btn ${location.pathname === '/' ? 'layout-home__header-btn--active' : ''}`}
                    to={`/?language=${language}`}
                  >
                    {translations['home'][language]}
                  </NavLink>
                  <NavLink
                    className={`layout-home__header-btn ${location.pathname === '/coaches' ? 'layout-home__header-btn--active' : ''}`}
                    to={`/coaches?language=${language}`}
                  >
                    {translations['coach'][language]}
                  </NavLink>
                  <NavLink
                    className={`layout-home__header-btn ${location.pathname === '/products' ? 'layout-home__header-btn--active' : ''}`}
                    to={`/products?language=${language}`}
                  >
                    {translations['product'][language]}
                  </NavLink>
                  <NavLink
                    className={`layout-home__header-btn ${location.pathname === '/business' ? 'layout-home__header-btn--active' : ''}`}
                    to={`/business?language=${language}`}
                  >
                    {translations['business'][language]}
                  </NavLink>
                  <NavLink
                    className={`layout-home__header-btn ${location.pathname === '/about' ? 'layout-home__header-btn--active' : ''}`}
                    to={`/about?language=${language}`}
                  >
                    {translations['aboutUs'][language]}
                  </NavLink>
                </div>
                <div className="layout-home__header-login-wrap">
                  <NavLink
                    className="layout-home__header-register"
                    to={`/register?language=${language}`}
                  >
                    {translations['registration'][language]}
                  </NavLink>
                  <NavLink
                    className="layout-home__header-login"
                    to={`/login?language=${language}`}
                  >
                    {translations['login'][language]}
                  </NavLink>
                  <SelectLanguage
                    isLayoutHome={true}
                    setIsPreloader={setIsPreloader}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <Outlet />
        <div className="layout-home__footer-wrap">
          <div className="layout-home__footer">
            <div className="layout-home__footer-main">
              <img
                className="layout-home__footer-main-logo"
                src={logo}
                alt="logo"
              />
              <div className="layout-home__footer-main-btn-wrap">
                <a
                  className="layout-home__footer-main-btn"
                  href="#"
                >
                  <img
                    className="layout-home__footer-main-btn-img"
                    src={instagram2}
                    alt="img"
                  />
                </a>
                <a
                  className="layout-home__footer-main-btn"
                  href="#"
                >
                  <img
                    className="layout-home__footer-main-btn-img"
                    src={tikTok}
                    alt="img"
                  />
                </a>
                <a
                  className="layout-home__footer-main-btn"
                  href="#"
                >
                  <img
                    className="layout-home__footer-main-btn-img"
                    src={facebook2}
                    alt="img"
                  />
                </a>
              </div>
              <div className="layout-home__footer-main-link-wrap">
                <NavLink
                  className="layout-home__footer-main-link"
                  to={`/?language=${language}`}
                >
                  {translations['home'][language]}
                </NavLink>
                <NavLink
                  className="layout-home__footer-main-link"
                  to={`/coaches?language=${language}`}
                >
                  {translations['coach'][language]}
                </NavLink>
                <NavLink
                  className="layout-home__footer-main-link"
                  to={`/products?language=${language}`}
                >
                  {translations['product'][language]}
                </NavLink>
                <NavLink
                  className="layout-home__footer-main-link"
                  to={`/about?language=${language}`}
                >
                  {translations['aboutUs'][language]}
                </NavLink>
              </div>
            </div>
          </div>
          <div className="layout-home__footer-bottom-wrap">
            <div className="layout-home__footer-bottom">
              <div className="layout-home__footer-bottom-text">Pro coach - WENOCODE OÜ.</div>
              <div className="layout-home__footer-bottom-link-wrap">
                <NavLink
                  className="layout-home__footer-bottom-link"
                  to={`/policy?language=${language}`}
                >
                  {translations['privacyPolicy'][language]}
                </NavLink>
                <NavLink
                  className="layout-home__footer-bottom-link"
                  to={`/terms?language=${language}`}
                >
                  {translations['terms'][language]}
                </NavLink>
                <NavLink
                  className="layout-home__footer-bottom-link"
                  to={`/license?language=${language}`}
                >
                  {translations['eula'][language]}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutHome;
