import './PasswordInput.css';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { memo, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { handleFocusAppInput } from '../../helpers/Utils';
import { useDispatch } from 'react-redux';
import { setIsInputFocus } from '../../store/userSlice';
import { useSelector } from 'react-redux';

function PasswordInput({ password, setPassword, label = null, isDisabled = false, handleKeyEnter = null, errorPassword = null }) {
  const is_app = useSelector((state) => state.homeSlice.is_app);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      className={`password-input ${password?.length ? 'mu-component-is-active' : ''} ${isDisabled ? 'disabledContainer' : ''}`}
      sx={{ m: 1, width: '25ch' }}
      variant="outlined"
    >
      <InputLabel htmlFor="outlined-adornment-password">{label ? label : 'Password'}</InputLabel>
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleKeyEnter && handleKeyEnter()}
        onFocus={() => handleFocusAppInput(true, (res) => dispatch(setIsInputFocus(res)), is_app)}
        onBlur={() => handleFocusAppInput(false, (res) => dispatch(setIsInputFocus(res)), is_app)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label ? label : 'Password'}
        aria-describedby="outlined-weight-helper-text"
      />
      {!!errorPassword?.length && (
        <FormHelperText
          className="password-input__error"
          id="outlined-weight-helper-text"
        >
          {errorPassword}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default memo(PasswordInput);
