const defoultMetaDescription = 'Pro Coach description';
const defoultMetaKeywords = 'Sports, training, exercises, fitness, health';

export const pageMeta = {
  SignUpView: {
    title: 'Sign Up',
    description: 'Sign Up description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  SignInView: {
    title: 'Sign In',
    description: 'Sign In description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  RestorePasswordView: {
    title: 'Restore password',
    description: 'Restore description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  NotFoundView: {
    title: 'Not found password',
    description: 'Not found description',
    keywords: 'Sports, training, exercises, fitness, health',
  },

  UserView: {
    title: 'Homepage',
    description: 'Homepage description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  CoachingView: {
    title: 'Training',
    description: 'Training description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  CoachingDayView: {
    title: 'Training day',
    description: 'Training description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  CoachingPlanView: {
    title: 'Training plan',
    description: 'Plan description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  InstructionView: {
    title: 'Instruction',
    description: 'Instruction description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  UserProfileView: {
    title: 'Profile',
    description: 'Profile description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  ScanningView: {
    title: 'Scanning',
    description: 'Scanning description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  AdminTrainingsView: {
    title: 'Trainings',
    description: 'Trainings description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  AdminTrainingView: {
    title: 'Training',
    description: 'Training description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  AdminTrainingDayView: {
    title: 'Training day',
    description: 'Training day description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  AdminAllPlansView: {
    title: 'Plans',
    description: 'Plans description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  AdminCreatePlanView: {
    title: 'Create plan',
    description: 'Plans description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  AdminUsersView: {
    title: 'Users',
    description: 'Users description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  AdminProfileView: {
    title: 'Profile',
    description: 'Profile description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  AdminNotificationsView: {
    title: 'Notifications',
    description: 'Notifications description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  ProductsView: {
    title: 'Products',
    description: 'Products description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  HomeView: {
    title: 'Home',
    description: 'Home description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  AboutView: {
    title: 'About',
    description: 'About description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  CoachesView: {
    title: 'Coaches',
    description: 'Coaches description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  BillingView: {
    title: 'Coaches',
    description: 'Coaches description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  LicenseView: {
    title: 'License',
    description: 'Coaches description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  TermsView: {
    title: 'Terms',
    description: 'Coaches description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
  PolicyView: {
    title: 'Policy',
    description: 'Coaches description',
    keywords: 'Sports, training, exercises, fitness, health',
  },
};
